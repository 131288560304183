import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { ReconciliationPosition } from '@pinnakl/poems/oms/domain';
import { SecurityPriceAlert, WatchlistItem } from '@pinnakl/shared/types';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  private readonly PNL_URL = 'entities/pnl_per_security';
  private readonly POSITION_SUMMARY = 'entities/positions_mv';
  private readonly TRADE_REQUEST_URL = 'entities/trades';
  private readonly WATCH_LIST_ITEMS_URL = 'v3/entities/watchlist_items';
  private readonly EXTERNAL_POSITIONS_RESOURCE_URL = 'entities/extpos';
  private readonly PRICE_CHART_URL = 'entities/security_price_timeseries';
  private readonly POSITION_CHART_URL = 'entities/security_position_timeseries';
  private readonly SECURITY_PRICE_ALERTS_URL = 'v3/entities/security_price_alerts';
  private readonly TRADE_ALLOCATION = 'entities/trade_allocations';
  selectedPositionPopupAccount$ = new BehaviorSubject<any>(null);

  constructor(private readonly wsp: WebServiceProvider) {}

  saveComment(id: number, comment: string): Promise<ReconciliationPosition> {
    return this.wsp.putHttp({
      endpoint: this.EXTERNAL_POSITIONS_RESOURCE_URL,
      body: {
        comment,
        id: id.toString()
      }
    });
  }

  getPriceHistoryChart(securityMarketId: number): Promise<any> {
    return this.wsp.getHttp({
      endpoint: this.PRICE_CHART_URL,
      optionsParams: {
        filters: [{ key: 'securityMarketId', type: 'EQ', value: [securityMarketId.toString()] }]
      }
    });
  }

  getPositionChart(securityMarketId: number): Promise<any> {
    return this.wsp.getHttp<any>({
      endpoint: this.POSITION_CHART_URL,
      optionsParams: {
        filters: [{ key: 'securityMarketId', type: 'EQ', value: [securityMarketId.toString()] }]
      }
    });
  }

  getTradeHistory(
    id: number | string,
    underlyingSecId: number,
    isUnderlyingEnabled = false
  ): Promise<any> {
    return this.wsp.getHttp<any>({
      endpoint: this.TRADE_REQUEST_URL,
      optionsParams: {
        fields: [
          'tradedate',
          'trantype',
          'ticker',
          'cusip',
          'description',
          'quantity',
          'price',
          'currency',
          'commission',
          'netmoneylocal',
          'brokername',
          'executingbrokername',
          'commissionpershare'
        ],
        filters: [
          {
            key: isUnderlyingEnabled ? 'underlyingsecid' : 'securitymarketid',
            type: 'EQ',
            value: [isUnderlyingEnabled ? underlyingSecId.toString() : id.toString()]
          }
        ]
      }
    });
  }

  getTradeAllocationsBySecurity(
    secId: number,
    underlyingSecId: number,
    isUnderlyingEnabled: boolean,
    accountId?: number
  ): Promise<any> {
    const filters = [
      {
        key: isUnderlyingEnabled ? 'underlyingsecid' : 'securitymarketid',
        type: 'EQ',
        value: [isUnderlyingEnabled ? underlyingSecId.toString() : secId]
      }
    ];
    if (accountId) {
      filters.push({ key: 'accountId', type: 'EQ', value: [accountId.toString()] });
    }
    return this.wsp.getHttp<any[]>({
      endpoint: this.TRADE_ALLOCATION,
      optionsParams: {
        fields: [
          'tradedate',
          'trantype',
          'ticker',
          'cusip',
          'description',
          'quantity',
          'price',
          'currency',
          'commission',
          'netmoneylocal',
          'brokername',
          'executingbrokername',
          'commissionPerShare'
        ],
        filters
      }
    });
  }

  getPositionSummary(securityMarketId: number, posDate: string, accountId?): Promise<any> {
    const filters = [
      {
        key: 'securityMarketId',
        type: 'EQ',
        value: [securityMarketId?.toString()]
      },
      {
        key: 'posdate',
        type: 'EQ',
        value: [posDate]
      }
    ];
    if (accountId && accountId !== 0) {
      filters.push({ key: 'accountId', type: 'EQ', value: [accountId] });
    }
    return this.wsp.getHttp({
      endpoint: this.POSITION_SUMMARY,
      optionsParams: {
        filters
      }
    });
  }

  getPnl(securityId: string, date: string): Promise<any> {
    return this.wsp.getHttp({
      endpoint: this.PNL_URL,
      optionsParams: {
        fields: ['AccountId', 'SecurityId', 'Mtd_Pnl', 'Ytd_Pnl'],
        filters: [
          {
            key: 'securityid',
            type: 'EQ',
            value: [securityId.toString()]
          },
          {
            key: 'PnlDate',
            type: 'EQ',
            value: [date]
          }
        ]
      }
    });
  }

  getLatestTradeDate(securityMarketId: string): Promise<any> {
    return this.wsp.getHttp({
      endpoint: this.TRADE_REQUEST_URL,
      optionsParams: {
        fields: ['tradedate'],
        filters: [
          {
            key: 'securityMarketId',
            type: 'EQ',
            value: [securityMarketId.toString()]
          },
          {
            key: '',
            type: 'TOP',
            value: ['1']
          }
        ],
        orderBy: [
          {
            field: 'tradedate',
            direction: 'DESC'
          }
        ]
      }
    });
  }

  async getPriceAlerts(): Promise<SecurityPriceAlert[]> {
    const fields = [
      'assetType',
      'securityMarketId',
      'ticker',
      'priceType',
      'condition',
      'price',
      'status',
      'insertTimestamp'
    ];
    const alerts = await this.wsp.getHttp<Partial<SecurityPriceAlert>[]>({
      endpoint: this.SECURITY_PRICE_ALERTS_URL,
      optionsParams: { fields }
    });
    return alerts.map(alert => new SecurityPriceAlert(alert));
  }

  async creatNewAlert(payload: {
    securityMarketId: number;
    priceType: string;
    condition: string;
    status: string;
    price: number;
  }): Promise<SecurityPriceAlert> {
    return this.wsp.postHttp({
      endpoint: this.SECURITY_PRICE_ALERTS_URL,
      body: payload
    });
  }

  async deleteAlert(id: string): Promise<any> {
    return this.wsp.deleteHttp({
      endpoint: `${this.SECURITY_PRICE_ALERTS_URL}/${id}`
    });
  }

  async getWatchlistItems(): Promise<WatchlistItem[]> {
    const fields = ['id', 'securityMarketId', 'ticker', 'assetType', 'insertTimestamp'];
    const items = await this.wsp.getHttp<Partial<WatchlistItem>[]>({
      endpoint: this.WATCH_LIST_ITEMS_URL,
      optionsParams: { fields }
    });
    return items.map(item => new WatchlistItem(item));
  }

  async addToWatchlist(payload: any): Promise<any> {
    return this.wsp.postHttp({
      endpoint: this.WATCH_LIST_ITEMS_URL,
      body: payload
    });
  }

  async deleteFromWatchlist(id: string): Promise<any> {
    return this.wsp.deleteHttp({
      endpoint: `${this.WATCH_LIST_ITEMS_URL}/${id}`
    });
  }
}
