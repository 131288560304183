import {
  BasePreset,
  BasePresetWithModuleBuilder,
  ParsedPreset,
  Preset,
  PresetConfigName,
  PresetModules
} from '@pinnakl/shared/types';

export const getObjectSimpleCopyOrUndefined = <T>(obj: T | undefined): T | undefined => {
  return obj ? { ...obj } : undefined;
};

/**
 * @param obj object that you would like to clean up from empty properties
 * @description provide T - generic interface for provided object
 * @returns Partial - Object without null fields
 * @example const obj = { a: 1, b: "b", c: null, d: [ ] } => @returns const obj = { a: 1, b: "b", d: [ ] }
 */
export const removeEmptyKeyValuesFromObject = <T>(obj: T): Partial<T> =>
  Object.entries(obj as unknown as Record<string, null | string>).reduce((acc, [key, value]) => {
    if (value === null) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});

/**
 * @param obj object that you would like to clean up from empty arrays as values
 * @description provide T - generic interface for provided object
 * @returns Partial - Object without empty arrays as values
 * @example const obj = { a: 1, b: "b", c: null, d: [ ] } => @returns const obj = { a: 1, b: "b", c: null }
 */
export const removeEmptyArraysValuesFromObject = <T>(obj: T): Partial<T> => {
  return Object.entries(obj as unknown as Record<string, string[]>).reduce((acc, [key, value]) => {
    if (Array.isArray(value) && value.length === 0) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});
};

/**
 * @param obj object that you would like to clean up from empty arrays as values
 * @param predicates array of predicates to run for each object field. If at least one return true -> remove the key-value from object
 * @description provide T - generic interface for provided object
 * @returns Partial - Object without success predicates executed
 * @example const obj = { a: 1, b: "b", c: null, d: [ ] }
 * @example predicates = [ (key, value) => value === null, (key, value) => Array.isArray(value) && value.length === 0 ]
 * @returns const obj = { a: 1, b: "b" }
 */
export const cleanUpObjectWithPredicates = <T>(
  obj: T,
  predicates: ((key: string, value: any) => boolean)[]
): Partial<T> => {
  if (predicates.length === 0) {
    return obj;
  }
  return Object.entries(obj as unknown as Record<string, string[]>).reduce((acc, [key, value]) => {
    if (predicates.some(predicate => predicate(key, value))) {
      return acc;
    }
    return {
      ...acc,
      [key]: value
    };
  }, {});
};

/**
 * @param predicates array of predicates to run for each object field. If at least one return true -> remove the key-value from object
 * @description provide function which is responsible for object clean up
 * @returns Partial - Object without success predicates executed
 * @example const obj = { a: 1, b: "b", c: null, d: [ ] }
 * @example predicates = [ (key, value) => value === null, (key, value) => Array.isArray(value) && value.length === 0 ]
 * @returns const obj = { a: 1, b: "b" }
 */
export const cleanUpObjectWithPredicatesHof =
  (predicates: ((key: string, value: any) => boolean)[]) =>
  <T>(obj: T): Partial<T> => {
    if (predicates.length === 0) {
      return obj;
    }
    return Object.entries(obj as unknown as Record<string, string[]>).reduce(
      (acc, [key, value]) => {
        if (predicates.some(predicate => predicate(key, value))) {
          return acc;
        }
        return {
          ...acc,
          [key]: value
        };
      },
      {}
    );
  };

/**
 * Mostly used in reducers to set loaded and loading fields in store
 * @param loaded
 * @param loading
 */
export const setLoadedAndLoadingFields = (loaded: boolean, loading: boolean) => ({
  loaded,
  loading
});

/**
 * Presets helpers
 * @param presetObj
 */
type GetPresetWithParsedConfigValueOverload = {
  <T>(presetObj: Preset): ParsedPreset<T>;
  <T>(presetObj: Preset | null): ParsedPreset<T> | null;
  (presetObj: null): null;
};

export const getPresetWithParsedConfigValue: GetPresetWithParsedConfigValueOverload = <T>(
  presetObj: any
): any => {
  let presetObjWithParsedConfigValue: ParsedPreset<T> | null = null;
  if (presetObj) {
    try {
      presetObjWithParsedConfigValue = {
        ...presetObj,
        parsedConfigValue: JSON.parse(presetObj.configValue)
      };
    } catch (e) {
      console.error("preset.configValue can't be parsed. Value: ", { presetObj });
    }
  }
  return presetObjWithParsedConfigValue;
};

export const buildBasePreset =
  (module: PresetModules): BasePresetWithModuleBuilder =>
  (configName: PresetConfigName, configValue: string): BasePreset => ({
    module,
    configName,
    configValue
  });

export const makeSomeFieldsToStringFactory =
  <T extends Record<string, any>, K extends Record<any, any>>(fieldsToConvert: string[]) =>
  (object: T): K =>
    fieldsToConvert.reduce(
      (acc, field) => ({ ...acc, [field]: acc[field].toString() }),
      object
    ) as unknown as K;

export const makeSomeFieldsToString = (object: Record<string, any>, fieldsToConvert: string[]) =>
  fieldsToConvert.reduce((acc, field) => ({ ...acc, [field]: acc[field].toString() }), object);

export const getErrorMessage = (message: string, err?: any): string => {
  const msgFromError = err?.error?.exceptionDetails?.[0]?.message;
  return typeof err === 'string'
    ? err
    : typeof err?.message === 'string'
      ? (msgFromError ?? err.message)
      : message;
};
