import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { PinnaklEnvironment } from './environment.model';
import { EnvironmentService } from './environment.service';
import { ENVIRONMENT_CONFIG, ENVIRONMENT_SERVICE } from './environment.tokens';

export interface EnvironmentConfig {
  config?: PinnaklEnvironment;
}

@NgModule({
  imports: [CommonModule],
  providers: [EnvironmentService]
})
export class EnvironmentModule {
  static forRoot(options: EnvironmentConfig): ModuleWithProviders<EnvironmentModule> {
    if (!options) throw new Error('Environment configuration was not defined');
    return {
      ngModule: EnvironmentModule,
      providers: [
        {
          provide: ENVIRONMENT_CONFIG,
          useValue: options.config
        },
        {
          provide: ENVIRONMENT_SERVICE,
          useClass: EnvironmentService,
          deps: [ENVIRONMENT_CONFIG]
        }
      ]
    };
  }
}
