export interface BaseUserFromApi {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  passwordResetRequired: boolean;
  active: boolean;
  authType: UserAuthType;
  phone: string | null;
  timezone: string | null;
  tokenReauthInterval: number | null;
  otpChannel: OtpChannelType | null;
  otpSecret: string | null;
  clientName: string | null;
  apps: string[] | null; // ['CRM']
  isExternal: boolean;
  roleFlag: string; // 'ClientAdmin, CustomerSupport'
  id: number;
  clientId: number;
  userType: UserTypes;
  roles?: string[]; // no field for Investor Portal
}

export type CurrentUserInfoUserFromApi = BaseUserFromApi;

interface CurrentUserInfoUserLocation {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
}

export enum UserRoles {
  ClientAdmin = 'ClientAdmin',
  CustomerSupport = 'CustomerSupport',
  IsDeveloper = 'IsDeveloper',
  TradingAccess = 'TradingAccess',
  ComplianceAccess = 'ComplianceAccess'
}

export interface CurrentUserInfoUser extends CurrentUserInfoUserFromApi {
  username?: string;
  rolesMap: Record<UserRoles, boolean>;
}

interface CurrentUserInfoGeneric<T> {
  user: T;
  token: string;
  lastAccessTime: string; // 'YYYY-MM-DDTHH:MM:SSZ'
  slidingExpiry: string; // '1.00:00:00'
  application: string;
  location: CurrentUserInfoUserLocation;
  fingerprint: string;
  roles: UserRoles[]; // ['ClientAdmin', 'CustomerSupport']
}

export type CurrentUserInfoFromApi = CurrentUserInfoGeneric<CurrentUserInfoUserFromApi>;
export type CurrentUserInfoParsed = CurrentUserInfoGeneric<CurrentUserInfoUser>;

export const userTimeZones = [
  { timeZone: '(UTC -5) New York' },
  { timeZone: '(UTC -6) Chicago' },
  { timeZone: '(UTC -7) Salt Lake City' },
  { timeZone: '(UTC -8) Los Angeles' },
  { timeZone: '(UTC -9) Anchorage' },
  { timeZone: '(UTC -10) Honolulu' },
  { timeZone: '(UTC 0) Greenwich Mean Time' },
  { timeZone: '(UTC +1) Central European Time' },
  { timeZone: '(UTC +8) China Standard Time' }
];

export enum UserAuthType {
  SINGLE_FACTOR = 'SingleFactor',
  TWO_FACTOR = 'TwoFactor',
  SSO = 'Sso'
}

export const AUTH_TYPES_MAP = {
  SINGLE_FACTOR: UserAuthType.SINGLE_FACTOR,
  TWO_FACTOR: UserAuthType.SINGLE_FACTOR,
  SSO: UserAuthType.SSO
};

export enum OtpChannelType {
  EMAIL = 'Email',
  MOBILE = 'Mobile',
  QR = 'Qr',
  Empty = ''
}

export const OTP_TYPES_MAP = {
  EMAIL: OtpChannelType.EMAIL,
  MOBILE: OtpChannelType.MOBILE,
  QR: OtpChannelType.QR
};

export const userAuthTypes = {
  singleFactor: {
    value: UserAuthType.SINGLE_FACTOR,
    label: 'Single-factor authentification'
  },
  multiFactor: {
    value: UserAuthType.TWO_FACTOR,
    label: 'Two-factor authentification'
  },
  sso: {
    value: UserAuthType.SSO,
    label: 'Office 365 Single Sign On'
  }
};

export const multiFactorTypes = {
  email: {
    value: OtpChannelType.EMAIL,
    label: 'Email'
  },
  mobile: {
    value: OtpChannelType.MOBILE,
    label: 'Mobile'
  },
  qr: {
    value: OtpChannelType.QR,
    label: 'QR code'
  }
};

export const requestAuthenticationHours = [4, 8, 24];

export enum UserTypes {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export enum AllowedUserTypeAuthParam {
  INTERNAL = 'i',
  EXTERNAL = 'e'
}

export interface UserFromApi {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  authType: UserAuthType;
  otpChannel: OtpChannelType;
  phone: string;
  timezone: null | string;
  tokenReauthInterval: number;
  passwordResetRequired: boolean;
  lastLoginAttempt: null;
  lastPasswordChange: null;
  active: boolean;
  roles: UserRoles[];
}

export interface User extends UserFromApi {
  rolesMap: Record<UserRoles, boolean>;
  fullName: string;
}
