import { UserRoles, UserTypes } from '@pinnakl/shared/types';

export function UserSideFactory(userTypes = UserTypes.INTERNAL): () => UserTypes {
  return function (): UserTypes {
    return userTypes;
  };
}

export const BuildUserRolesMap = (userRoles?: string[]): Record<UserRoles, boolean> =>
  userRoles
    ? userRoles.reduce(
        (rolesMap, role) => ({
          ...rolesMap,
          [role]: true
        }),
        {} as Record<UserRoles, boolean>
      )
    : ({} as Record<UserRoles, boolean>);
