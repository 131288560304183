/* eslint-disable @typescript-eslint/ban-types */
import { computed, Signal } from '@angular/core';
import {
  SignalStoreFeature,
  signalStoreFeature,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { StateSignal } from '@ngrx/signals/src/state-signal';
import { capitalize } from './data-service-feature';
import { patchState } from './with-devtools';

export type CallState =
  | 'init'
  | 'loadingOne'
  | 'loadedOne'
  | 'loadingMany'
  | 'loadedMany'
  | 'processing'
  | { error: string };

export type NamedCallStateSlice<Collection extends string> = {
  [K in Collection as `${K}CallState`]: CallState;
};

export type CallStateSlice = {
  callState: CallState;
};

export type NamedCallStateSignals<Prop extends string> = {
  [K in Prop as `${K}LoadingOne`]: Signal<boolean>;
} & {
  [K in Prop as `${K}LoadedOne`]: Signal<boolean>;
} & {
  [K in Prop as `${K}LoadingMany`]: Signal<boolean>;
} & {
  [K in Prop as `${K}LoadedMany`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Processing`]: Signal<boolean>;
} & {
  [K in Prop as `${K}Error`]: Signal<string | null>;
};

export type CallStateSignals = {
  loadingOne: Signal<boolean>;
  loadedOne: Signal<boolean>;
  loadingMany: Signal<boolean>;
  loadedMany: Signal<boolean>;
  processing: Signal<boolean>;
  error: Signal<string | null>;
};

export type NamedCallStateMethods<Collection extends string, P extends boolean> = {
  [K in Collection as `update${Capitalize<K>}LoadedOne`]: (payload: P) => void;
} & {
  [K in Collection as `update${Capitalize<K>}LoadedMany`]: (payload: P) => void;
};

export type CallStateMethods<P extends boolean> = {
  updateLoadedOne: (payload: P) => void;
  updateLoadedMany: (payload: P) => void;
};

export function getCallStateKeys(config?: { collection?: string }): {
  callStateKey: string;
  loadingOneKey: string;
  updateLoadedOneKey: string;
  loadedOneKey: string;
  loadingManyKey: string;
  updateLoadedManyKey: string;
  loadedManyKey: string;
  processingKey: string;
  errorKey: string;
} {
  const prop = config?.collection;
  return {
    callStateKey: prop ? `${config.collection}CallState` : 'callState',
    loadingManyKey: prop ? `${config.collection}LoadingMany` : 'loadingMany',
    loadingOneKey: prop ? `${config.collection}LoadingOne` : 'loadingOne',
    updateLoadedOneKey: prop ? `update${capitalize(prop)}LoadedOne` : 'updateLoadedOne',
    updateLoadedManyKey: prop ? `update${capitalize(prop)}LoadedMany` : 'updateLoadedMany',
    loadedOneKey: prop ? `${config.collection}LoadedOne` : 'loadedOne',
    loadedManyKey: prop ? `${config.collection}LoadedMany` : 'loadedMany',
    processingKey: prop ? `${config.collection}Processing` : 'processing',
    errorKey: prop ? `${config.collection}Error` : 'error'
  };
}

export function withCallState<Collection extends string, P extends boolean>(config: {
  collection: Collection;
}): SignalStoreFeature<
  { state: {}; signals: {}; methods: {} },
  {
    state: NamedCallStateSlice<Collection>;
    signals: NamedCallStateSignals<Collection>;
    methods: NamedCallStateMethods<Collection, P>;
  }
>;
export function withCallState<P extends boolean>(): SignalStoreFeature<
  { state: {}; signals: {}; methods: {} },
  {
    state: CallStateSlice;
    signals: CallStateSignals;
    methods: CallStateMethods<P>;
  }
>;

export function withCallState<Collection extends string>(config?: {
  collection: Collection;
}): SignalStoreFeature {
  const {
    callStateKey,
    errorKey,
    loadingOneKey,
    loadedOneKey,
    updateLoadedOneKey,
    loadingManyKey,
    loadedManyKey,
    updateLoadedManyKey,
    processingKey
  } = getCallStateKeys(config);

  return signalStoreFeature(
    withState({ [callStateKey]: 'init', [loadedOneKey]: false, [loadedManyKey]: false }),
    withComputed((state: Record<string, Signal<unknown>>) => {
      const callState = state[callStateKey] as Signal<CallState>;

      return {
        [loadingOneKey]: computed(() => callState() === 'loadingOne'),
        // [loadedOneKey]: computed(() => callState() === 'loadedOne'),
        [loadingManyKey]: computed(() => callState() === 'loadingMany'),
        // [loadedManyKey]: computed(() => callState() === 'loadedMany'),
        [processingKey]: computed(() => callState() === 'processing'),
        [errorKey]: computed(() => {
          const v = callState();
          return typeof v === 'object' ? v.error : null;
        })
      };
    }),
    withMethods((store: Record<string, unknown> & StateSignal<object>) => {
      return {
        [updateLoadedOneKey]: (loaded: boolean): void => {
          patchState(store, updateLoadedOneKey, { [loadedOneKey]: loaded });
        },
        [updateLoadedManyKey]: (loaded: boolean): void => {
          patchState(store, updateLoadedManyKey, { [loadedManyKey]: loaded });
        }
      };
    })
  );
}

export function setLoadingOne<Prop extends string>(
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  if (prop) {
    return { [`${prop}CallState`]: 'loadingOne' } as NamedCallStateSlice<Prop>;
  }

  return { callState: 'loadingOne' };
}

export function setLoadedOne<Prop extends string>(
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  if (prop) {
    return { [`${prop}CallState`]: 'loadedOne' } as NamedCallStateSlice<Prop>;
  } else {
    return { callState: 'loadedOne' };
  }
}

export function setLoadingMany<Prop extends string>(
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  if (prop) {
    return { [`${prop}CallState`]: 'loadingMany' } as NamedCallStateSlice<Prop>;
  }

  return { callState: 'loadingMany' };
}

export function setLoadedMany<Prop extends string>(
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  if (prop) {
    return { [`${prop}CallState`]: 'loadedMany' } as NamedCallStateSlice<Prop>;
  } else {
    return { callState: 'loadedMany' };
  }
}

export function setProcessing<Prop extends string>(
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  if (prop) {
    return { [`${prop}CallState`]: 'processing' } as NamedCallStateSlice<Prop>;
  } else {
    return { callState: 'processing' };
  }
}

export function setError<Prop extends string>(
  error: unknown,
  prop?: Prop
): NamedCallStateSlice<Prop> | CallStateSlice {
  let errorMessage = '';

  if (!error) {
    errorMessage = '';
  } else if (typeof error === 'object' && 'message' in error) {
    errorMessage = String(error.message);
  } else {
    errorMessage = String(error);
  }

  if (prop) {
    return { [`${prop}CallState`]: { error: errorMessage } } as NamedCallStateSlice<Prop>;
  } else {
    return { callState: { error: errorMessage } };
  }
}
