import { createAction, props } from '@ngrx/store';
import { SecurityAssetType } from '@pinnakl/securities/domain';

export enum AssetTypesActions {
  LoadAssetTypes = '[AssetTypes] Load Asset Types',
  AssetTypesLoadSuccess = '[AssetTypes] Asset Types Load Success',
  AssetTypesLoadFailure = '[AssetTypes] Asset Types Load Failure'
}

export const loadAssetTypes = createAction(AssetTypesActions.LoadAssetTypes);
export const assetTypesLoadSuccess = createAction(
  AssetTypesActions.AssetTypesLoadSuccess,
  props<{ assetTypes: SecurityAssetType[] }>()
);
export const assetTypesLoadFailure = createAction(
  AssetTypesActions.AssetTypesLoadFailure,
  props<{ error: any }>()
);
