import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Account } from '@pinnakl/shared/types';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private readonly ACCOUNTS_URL = 'v3/entities/accounts';
  private _accounts: Account[];

  constructor(private wsp: WebServiceProvider) {}

  getAccounts(): Promise<Account[]> {
    if (this._accounts) {
      return Promise.resolve(this._accounts);
    }
    return this.wsp
      .getHttp<Account[]>({
        endpoint: this.ACCOUNTS_URL
      })
      .then(accounts => {
        this._accounts = accounts;
        return this._accounts;
      });
  }
}
