import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SecuritiesStore, SecurityMarketsStore } from '@pinnakl/securities/data-access';
import { SecurityMarketDetails } from '@pinnakl/securities/domain';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { BehaviorSubject, defer, iif, of, switchMap } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import {
  addAlpha,
  getCurrentSymbol,
  mapSecuritiesData,
  SearchSecurityItem
} from '../security-items';

const addParamIfExist = (initialString: string, param?: string): string =>
  param ? initialString + `${param} - ` : initialString;

@UntilDestroy()
@Component({
  selector: 'security-selector',
  templateUrl: 'security-selector.component.html',
  styleUrls: ['security-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecuritySelectorComponent implements OnInit {
  private readonly securitiesStore = inject(SecuritiesStore);
  private readonly securityMarketsStore = inject(SecurityMarketsStore);
  // We need select template variable to access tetx input in kendo combo box
  @ViewChild('select', { static: false }) select: any;

  addAlpha = addAlpha;
  getCurrentSymbol = getCurrentSymbol;

  @Input() assetType = '';
  @Input() isLocal = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() valueField = 'id';
  @Input() label = 'SECURITY';
  @Input() includeCheckForExpiration = false;
  @Input() control?: FormControl<SearchSecurityItem>;
  selectorSecurities: SearchSecurityItem[] = [];
  searchText$ = new BehaviorSubject('');
  isLoading$ = new BehaviorSubject(false);
  colors = ['#ba68c8', '#ffb74d', '#4dd0e1', '#673ab7'];
  selectorControl = new FormControl<SearchSecurityItem>(undefined);
  dropdownCollection$ = new BehaviorSubject<SearchSecurityItem[]>([]);

  @Input() set prefetchedSecurities(securities: SecurityMarketDetails[] | SecuritySearchResult[]) {
    this.dropdownCollection$.next(this.setParamsToData(securities));
  }

  @Input() additionalSecuritiesFilter: (sec: SecuritySearchResult) => boolean = () => true;

  ngOnInit(): void {
    this.handleDropdownSearching();
    this.handleControlInitialValue();
    this.handleSetValueOutside();
    this.handleSecuritySelectorControlChanges();
  }

  filterDropdown(text: string): void {
    this.searchText$.next(text);
    if (this.selectorSecurities?.length) {
      this.dropdownCollection$.next(
        this.selectorSecurities.filter(security =>
          security.searchString.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  }

  private handleSecuritySelectorControlChanges(): void {
    this.selectorControl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap(security =>
          iif(
            () => this.isLocal,
            of(security),
            defer(() =>
              security?.id
                ? this.securityMarketsStore.loadSecurityMarketDetails(security.securityMarketId)
                : of(undefined)
            )
          )
        )
      )
      .subscribe((security: any) => this.control.patchValue(mapSecuritiesData([security])[0]));
  }

  private handleControlInitialValue(): void {
    const controlInitialValue = this.control?.value;
    if (controlInitialValue) {
      this.processSecurity(controlInitialValue);
    }
  }

  private handleSetValueOutside(): void {
    this.control?.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(this.processSecurity.bind(this));
  }

  private processSecurity(controlInitialValue: SearchSecurityItem): void {
    console.log('controlInitialValue', controlInitialValue);
    // if (controlInitialValue.market) {
    //   this.processSecurityByModelType(controlInitialValue);
    // } else {
    //   this.selectorControl.patchValue(undefined, { emitEvent: false });
    // }
  }

  private handleDropdownSearching(): void {
    this.searchText$
      .pipe(
        filter(() => !this.isLocal),
        debounceTime(300),
        filter(text => text.length > 1),
        tap(() => this.isLoading$.next(true)),
        switchMap(text =>
          this.securitiesStore.searchSecurities(
            text,
            this.assetType,
            [],
            this.includeCheckForExpiration
          )
        ),
        tap(() => this.isLoading$.next(false)),
        untilDestroyed(this)
      )
      .subscribe(data =>
        this.dropdownCollection$.next(
          this.setParamsToData(data.filter(this.additionalSecuritiesFilter))
        )
      );
  }

  private processSecurityByModelType(securityOutside: SecurityMarketDetails): void {
    // if (securityOutside) {
    //   const securityFromCollection = this.dropdownCollection$.value.find(
    //     security => security.securityMarketId === securityOutside.market.id
    //   );
    //
    //   if (securityFromCollection) {
    //     this.selectorControl.patchValue(securityFromCollection, { emitEvent: false });
    //   } else {
    //     const outsideSecurityInCollection = this.formatSecuritySearchResult(
    //       SecurityService[securityModelMapperName](securityOutside as any)
    //     );
    //     this.dropdownCollection$.next([
    //       ...this.dropdownCollection$.value,
    //       outsideSecurityInCollection
    //     ]);
    //     this.selectorControl.patchValue(outsideSecurityInCollection, { emitEvent: false });
    //   }
    // } else {
    //   this.selectorControl.patchValue(undefined, { emitEvent: false });
    // }
  }

  private setParamsToData(
    securities: SecurityMarketDetails[] | SecuritySearchResult[]
  ): SearchSecurityItem[] {
    if (securities?.length) {
      this.selectorSecurities = mapSecuritiesData(securities);
      return this.selectorSecurities;
    }
    return [];
  }

  private formatSecuritySearchResult(security: SecuritySearchResult): SearchSecurityItem {
    return {
      ...security,
      color: this.colors[Math.floor(Math.random() * this.colors.length)],
      searchString:
        addParamIfExist(
          addParamIfExist(addParamIfExist('', security.assetType), security.ticker),
          security.cusip
        ) + security.description
    };
  }
}
