import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CurrentUserStore } from '@pinnakl/core/data-providers';
import { AccountsFacadeService } from '@pinnakl/poems/accounts/data-access';
import { AumFacadeService } from '@pinnakl/poems/aum/data-access';
import { UserRoles } from '@pinnakl/shared/types';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { PmsColumnsFacadeService } from '../columns';
import { PmsGridColumnsDataManager } from './grid-col-defs-data-manager.service';
import { prepareGridColumns, setGridColumns } from './grid-columns.actions';

@Injectable()
export class PmsGridColumnsEffects {
  private readonly currentUserStore = inject(CurrentUserStore);
  prepareGridColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareGridColumns),
      switchMap(() =>
        combineLatest([
          this.columnsFacade.columns$.pipe(filter(data => !!data?.length)),
          this.accountsFacade.accounts$.pipe(filter(data => !!data?.length)),
          this.aumFacade.aums$.pipe(filter(data => !!data?.length))
        ]).pipe(
          take(1),
          map(([columns, accounts, aums]) =>
            this.columnsDataManager.prepareCofDefs$(
              columns,
              accounts,
              aums,
              !!this.currentUserStore.currentUser()?.rolesMap[UserRoles.IsDeveloper]
            )
          )
        )
      ),
      map(colDefs => setGridColumns({ colDefs }))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly columnsFacade: PmsColumnsFacadeService,
    private readonly columnsDataManager: PmsGridColumnsDataManager,
    private readonly accountsFacade: AccountsFacadeService,
    private readonly aumFacade: AumFacadeService
  ) {}
}
