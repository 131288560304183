import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlockedIpsService } from './blocked-ips.service';
import { CustomAttributesService } from './custom-attributes.service';
import { InvestorModule } from './investor';
import { MarksService } from './marks.service';
import { PresetsService } from './presets.service';
import { ReportingService } from './reporting.service';

@NgModule({
  imports: [CommonModule, InvestorModule],
  providers: [
    CustomAttributesService,
    MarksService,
    PresetsService,
    ReportingService,
    BlockedIpsService
  ]
})
export class CoreDataProvidersModule {}
