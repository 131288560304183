import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SecurityType } from '@pinnakl/securities/domain';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import {
  loadSecurityTypes,
  loadSecurityTypesFailure,
  loadSecurityTypesSuccess
} from './security-types.actions';
import { SecurityTypesState } from './security-types.models';

export const adapter: EntityAdapter<SecurityType> = createEntityAdapter<SecurityType>();

export const initialState: SecurityTypesState = adapter.getInitialState(
  setLoadedAndLoadingFields(false, false)
);

export const securityTypesReducer = createReducer(
  initialState,
  on(loadSecurityTypes, state => ({ ...state, ...setLoadedAndLoadingFields(false, true) })),
  on(loadSecurityTypesSuccess, (state, { securityTypes }) =>
    adapter.setAll(securityTypes, {
      ...state,
      ...setLoadedAndLoadingFields(true, false)
    })
  ),
  on(loadSecurityTypesFailure, state => ({
    ...state,
    ...setLoadedAndLoadingFields(false, false)
  }))
);
