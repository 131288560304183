import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SecurityTypesState } from './security-types.models';
import { adapter } from './security-types.reducer';

export const featureSelectorName = 'security-types';

const getSecurityTypesStateSelector =
  createFeatureSelector<SecurityTypesState>(featureSelectorName);

const { selectAll } = adapter.getSelectors();

const getSecurityTypes = createSelector(getSecurityTypesStateSelector, selectAll);
const getSecurityTypesLoaded = createSelector(
  getSecurityTypesStateSelector,
  ({ loaded }) => loaded
);
const getSecurityTypesLoading = createSelector(
  getSecurityTypesStateSelector,
  ({ loading }) => loading
);

export const securityTypesQuery = {
  getSecurityTypes,
  getSecurityTypesLoaded,
  getSecurityTypesLoading
};
