import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { SecurityMarketDetails } from '@pinnakl/securities/domain';
import { SecuritySearchResult } from '@pinnakl/shared/types';
import { SearchSecurityItem } from './security-item.model';
import { addAlpha, getCurrentSymbol, mapSecuritiesData } from './security-item.util';

@Component({
  selector: 'pnkl-security-items',
  templateUrl: './security-items.component.html',
  styleUrls: ['./security-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityItemsComponent {
  private _items: SearchSecurityItem[];
  @ViewChildren('securityItem', { read: ElementRef }) securityItems: QueryList<ElementRef>;
  activeItemIndex = -1;
  getCurrentSymbol = getCurrentSymbol;
  addAlpha = addAlpha;
  @Input() searchText = '';
  @Output() itemClicked = new EventEmitter<SearchSecurityItem>();

  get items(): SearchSecurityItem[] {
    return this._items;
  }

  @Input() set items(v: SecuritySearchResult[] | SecurityMarketDetails[]) {
    this._items = mapSecuritiesData(v);
  }

  @HostListener('document:keydown', ['$event'])
  arrowKeysHandler(event: KeyboardEvent): void {
    const keypressHandlers = {
      ArrowDown: () => {
        const nextElementIndex =
          ++this.activeItemIndex > this.securityItems.length - 1 ? 0 : this.activeItemIndex;

        this.securityItems.get(nextElementIndex).nativeElement.focus();
        this.activeItemIndex = nextElementIndex;
      },
      ArrowUp: () => {
        const nextElementIndex =
          --this.activeItemIndex < 0 ? this.securityItems.length - 1 : this.activeItemIndex;

        this.securityItems.get(nextElementIndex).nativeElement.focus();
        this.activeItemIndex = nextElementIndex;
      },
      Enter: () => {
        const eventType = this.activeItemIndex >= 0 ? 'Select' : 'ArrowDown';

        keypressHandlers[eventType]();
      },
      Select: () => {
        const selectedItem = this.items[this.activeItemIndex];

        this.itemSelected(selectedItem);
      }
    };

    if (keypressHandlers[event.key]) {
      keypressHandlers[event.key]();
    }
  }

  resetIndexOnBlur(): void {
    this.activeItemIndex = -1;
  }

  itemSelected(item: SearchSecurityItem): void {
    this.itemClicked.emit(item);
  }
}
