import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssetTypesState } from './asset-types.models';
import { adapter } from './asset-types.reducer';

export const featureSelectorName = 'asset-types';

const getAssetTypesStateSelector = createFeatureSelector<AssetTypesState>(featureSelectorName);

const { selectAll } = adapter.getSelectors();

const getAssetTypes = createSelector(getAssetTypesStateSelector, selectAll);
const getAssetTypesLoaded = createSelector(getAssetTypesStateSelector, ({ loaded }) => loaded);
const getAssetTypesLoading = createSelector(getAssetTypesStateSelector, ({ loading }) => loading);

export const assetTypesQuery = {
  getAssetTypes,
  getAssetTypesLoaded,
  getAssetTypesLoading
};
