import { Location } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, SubscriptionLike } from 'rxjs';
import { filter, shareReplay, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NavigationService implements OnDestroy {
  private history: string[] = [];
  private browserBackListener!: SubscriptionLike;
  public readonly scroll$ = new BehaviorSubject(false);

  get routeParams(): Params {
    return this.router.parseUrl(this.router?.routerState?.snapshot?.url ?? '')?.queryParams ?? {};
  }

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnDestroy(): void {
    this.browserBackListener.unsubscribe();
  }

  public startSaveHistory(): Observable<NavigationEnd> {
    // TODO maybe add check if this.location.path() is valid route to save
    this.history.push(this.location.path());
    this.browserBackListener = this.location.subscribe(event => {
      if (event?.type === 'popstate') {
        this.popHistory();
        this.scroll$.next(true);
      }
    });
    return this.onNavigationEnd$().pipe(
      tap((event: NavigationEnd) => {
        // TODO maybe add check if event.urlAfterRedirects is valid route to save
        const lastUrl = this.history[Math.max(this.history.length - 1, 0)];
        if (lastUrl !== event.urlAfterRedirects) {
          this.history.push(event.urlAfterRedirects);
        }
      })
    );
  }

  public onNavigationEnd$(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public getHistory(): string[] {
    return this.history;
  }

  public popHistory(): void {
    this.history.pop();
  }

  public pushHistory(url: string): void {
    this.history.push(url);
  }

  public goBack(url = '/', forceUrlRedirect = false, withScroll = false): void {
    const hasHistory =
      this.history.length > 0 &&
      !(this.history.length === 1 && this.history[0] === this.location.path());
    if (hasHistory && !forceUrlRedirect) {
      this.location.back();
      this.scroll$.next(withScroll);
    } else {
      this.router.navigateByUrl(url).then(() => this.scroll$.next(withScroll));
    }
  }

  public getRouteQueryParams(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }
}
