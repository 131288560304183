import { Injectable, NgZone } from '@angular/core';
import { Dictionary, Update } from '@ngrx/entity';
import {
  AccountCode,
  CASH_ASSETTYPE,
  CustomFiltersConfig,
  EmptyColumnsWithFiltersMap,
  PmsAllFiltersConfig,
  PmsCalculationsWorkerDataRequest,
  PmsCalculationsWorkerDataResponse,
  PmsColumn,
  PmsDirection,
  PmsFxRate,
  PmsGridColDef,
  PmsGridColumns,
  PmsMergedEvent,
  PmsPosition,
  PmsRebalanceConfig,
  REBALANCE_COLUMN_KEYS
} from '@pinnakl/pms/domain';
import { AccountsFacadeService } from '@pinnakl/poems/accounts/data-access';
import { Account } from '@pinnakl/poems/accounts/domain';
import { Aum } from '@pinnakl/poems/aum/domain';
import { FxRate } from '@pinnakl/poems/domain';
import { RealtimePriceOverride } from '@pinnakl/poems/pricing/domain';
import { AccountCash } from '@pinnakl/poems/streams/portfolio/data-access';
import {
  CellEmptyComponent,
  CellInlineSvgComponent,
  CellRendererDistinctValueComponent,
  NumberFormatOptions,
  checkmarkCellRenderer,
  dateFormatter,
  decimalFormatter,
  getColumnAggregations,
  numericCellRenderer,
  textCellRenderer
} from '@pinnakl/shared-ui';
import {
  FutureAssetValueFormatter,
  GlobalNumberRound,
  isNeitherNullNorUndefined
} from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import {
  CellRendererSelectorResult,
  ColDef,
  ColumnState,
  GridApi,
  ICellRendererParams,
  IRowNode,
  NewValueParams,
  ValueFormatterParams
} from 'ag-grid-community';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { PmsPositionsFacadeService } from '../positions';
import { PmsPresetsFacadeService } from '../presets';
import { PmsRebalanceConfigStateFacadeService } from '../rebalance-config';

const CombineFiltersAndAccountCodes = (
  filters: CustomFiltersConfig | null,
  accountCodeFilter: string[]
): PmsAllFiltersConfig => ({
  ...EmptyColumnsWithFiltersMap,
  ...(filters ?? {}),
  [AccountCode]: accountCodeFilter
});

@Injectable()
export class PmsGridColumnsDataManager {
  private _columnState: BehaviorSubject<PmsGridColumns | null> =
    new BehaviorSubject<PmsGridColumns | null>(null);
  private _columnDefinitions: BehaviorSubject<PmsGridColDef[] | null> = new BehaviorSubject<
    PmsGridColDef[] | null
  >(null);
  private _columnsAllFilterValues = new BehaviorSubject<PmsAllFiltersConfig | null>(null);
  private _customFilters$ = new BehaviorSubject<CustomFiltersConfig | null>(null);
  private _accountCodes$ = new BehaviorSubject<string[]>([]);
  private readonly calculationsWorker: Worker;
  private readonly realtimePriceEventsDataWorker: Worker;
  private readonly isLogMode = localStorage.getItem('workersLogger') === 'true';
  gridApi: GridApi | null = null;
  readonly columnState$ = this._columnState.asObservable();
  readonly columnDefinitions$ = this._columnDefinitions.asObservable();
  readonly columnsAllFilterValues$ = this._columnsAllFilterValues.asObservable().pipe(
    filter(isNeitherNullNorUndefined),
    distinctUntilChanged((a, b) => isEqual(a, b))
  );
  readonly customFilters$ = this._customFilters$.asObservable();
  readonly accountCodes$ = this._accountCodes$.asObservable();

  get defaultColDefs(): ColDef {
    return {
      enableRowGroup: true,
      showRowGroup: false,
      filter: true,
      sortable: true,
      resizable: true,
      minWidth: 70,
      wrapText: true,
      editable: false
    };
  }

  get autoGroupColumnDef(): ColDef {
    return {
      sortable: true
    };
  }

  get currentColumnDefs(): PmsGridColDef[] {
    return this._columnDefinitions.value ?? [];
  }

  constructor(
    private readonly accountsFacade: AccountsFacadeService,
    private readonly presetsFacade: PmsPresetsFacadeService,
    private readonly positionsFacade: PmsPositionsFacadeService,
    private readonly rebalanceConfigFacade: PmsRebalanceConfigStateFacadeService,
    private readonly toast: PinnaklUIToastMessage,
    private readonly ngZone: NgZone
  ) {
    this.calculationsWorker = new Worker(
      new URL('libs/workers/pms-data-calculations.worker', import.meta.url),
      {
        type: 'module',
        name: 'pms-data-calculations-worker'
      }
    );

    this.realtimePriceEventsDataWorker = new Worker(
      new URL('libs/workers/realtime-events-data.worker', import.meta.url),
      {
        type: 'module',
        name: 'realtime-events-data-worker'
      }
    );

    this.handleCalculationsWorkerMessages();

    this.handleRealtimePriceEvents();
  }

  setGridControls({ api }: { api: GridApi | null }): void {
    this.gridApi = api;
    api && this.updateGridColumnState();
  }

  updateGridColumnState(
    checkStateEqualForPresetChangeCheck = true,
    triggerPresetChanged = true
  ): void {
    this.ngZone.runOutsideAngular(() => {
      const state = this.gridApi?.getColumnState();
      const newState = state?.map(({ colId, hide, sort }) => ({
        colId,
        sort,
        hide
      }));
      const currentState = this._columnState.getValue()?.map(({ colId, hide, sort }) => ({
        colId,
        sort,
        hide
      }));
      const presetChanged =
        newState && currentState && checkStateEqualForPresetChangeCheck
          ? !isEqual(newState, currentState)
          : false;
      const columnDefs = this.gridApi?.getColumnDefs() as PmsGridColDef[];
      state && this._columnState.next(state);
      columnDefs && this._columnDefinitions.next(columnDefs);
      triggerPresetChanged && this.presetsFacade?.presetChanged(presetChanged);
    });
  }

  resetColumnState(): void {
    this._columnState.next(null);
    this._columnDefinitions.next(null);
    this._columnsAllFilterValues.next(null);
  }

  resetCustomFiltersState(): void {
    this._customFilters$.next(null);
  }

  updateColumnsAllFilterValues(filterValues: PmsAllFiltersConfig): void {
    this.accountsFacade.accounts$.pipe(take(1)).subscribe(accounts =>
      this._columnsAllFilterValues.next({
        ...filterValues,
        accountcode: accounts.map(a => a.accountCode.toUpperCase())
      })
    );
  }

  setAccountFilter(accountCodes: string[], withPresetChanged = true): void {
    this._accountCodes$.next(accountCodes);
    withPresetChanged && this.presetsFacade.presetChanged();
  }

  setCustomFilters(filters: CustomFiltersConfig | null, withPresetChanged = true): void {
    this._customFilters$.next(filters);
    withPresetChanged && this.presetsFacade.presetChanged();
  }

  getAllPmsFilters(): PmsAllFiltersConfig {
    return CombineFiltersAndAccountCodes(this._customFilters$.value, this._accountCodes$.value);
  }

  processCalculations(
    positionsArray: PmsPosition[],
    columns: PmsColumn[],
    generalData: {
      aums: Aum[];
      fxRates: PmsFxRate[];
      accountCash: AccountCash[] | undefined;
      priceOverridesMap: Dictionary<RealtimePriceOverride>;
    },
    searchString: string,
    rebalance: {
      rebalanceConfig: PmsRebalanceConfig | null;
    }
  ): void {
    const columnsState = this.gridApi?.getColumnState();
    if (!columnsState?.length) {
      return;
    }

    const postMessage: PmsCalculationsWorkerDataRequest = {
      positionsArray,
      columns: { columnsFromGrid: columnsState, columnsList: columns },
      generalData,
      filters: {
        searchString,
        userFilters: CombineFiltersAndAccountCodes(
          this._customFilters$.getValue(),
          this._accountCodes$.getValue()
        )
      },
      rebalance
    };

    this.calculationsWorker.postMessage(postMessage);
  }

  updateRowsWithPriceEventData(
    messages: PmsMergedEvent[],
    pmsPositions: PmsPosition[],
    fxRates: FxRate[] | null
  ): void {
    this.isLogMode &&
      console.log('realtimePriceEventsDataWorker [START]', {
        data: {
          messages,
          positions: pmsPositions,
          fxRates
        },
        date: new Date()
      });

    pmsPositions.length &&
      this.realtimePriceEventsDataWorker.postMessage({
        messages,
        positions: pmsPositions,
        fxRates
      });
  }

  resetRebalanceRecords(positionsIds?: string[]): void {
    const updatedRows: Update<PmsPosition>[] = [];
    this.gridApi?.forEachNode((row: IRowNode<PmsPosition>) => {
      const id = row.data?.id;
      if (id) {
        const hasRebalanceKeys = Object.values(REBALANCE_COLUMN_KEYS).some(key => row.data?.[key]);
        const isOrderStaged = positionsIds ? positionsIds.includes(id) : true;

        if (hasRebalanceKeys && isOrderStaged) {
          updatedRows.push({
            id,
            changes: {
              rebalanceBy: undefined,
              tgtpct: undefined,
              rebalancequantity: undefined,
              rebalancecost: undefined
            }
          });
        }
      }
    });

    this.rebalanceConfigFacade.resetStagedRowsForRebalance(positionsIds);
    this.positionsFacade.updateManyPositions(updatedRows);
  }

  prepareCofDefs$(
    columns: PmsColumn[],
    accounts: Account[],
    aum: Aum[],
    currentUserIsDev: boolean
  ): PmsGridColDef[] {
    return this.buildColDefs(columns, accounts, aum, currentUserIsDev);
  }

  autoSizeAllColumns(): void {
    this.gridApi?.autoSizeAllColumns(false);
  }

  sizeColumnsToFit(): void {
    this.gridApi?.sizeColumnsToFit();
  }

  userResizeColumn(): void {
    this.presetsFacade.presetChanged();
  }

  getColumnsStateToSave(): ColumnState[] | undefined {
    return this.gridApi?.getColumnState().map(column => {
      const isRebalanceColumn = (Object.values(REBALANCE_COLUMN_KEYS) as string[]).includes(
        column.colId
      );
      if (isRebalanceColumn) {
        return {
          ...column,
          hide: true
        };
      }
      return column;
    });
  }

  private handleCalculationsWorkerMessages(): void {
    this.calculationsWorker.onmessage = ({
      data
    }: {
      data: PmsCalculationsWorkerDataResponse;
    }): void => {
      this.isLogMode &&
        console.log('calculationsWorker [END]', {
          data,
          date: new Date()
        });

      if (Array.isArray(data.grid.calculatedPositions)) {
        this.positionsFacade.setCalculatedGridPositions(data.grid.calculatedPositions);
      }
      if (data.grid.pinnedRows.length) {
        this.positionsFacade.setPinnedData(data.grid.pinnedRows);
      }
      if (Array.isArray(data.allCalculatedPositions)) {
        this.positionsFacade.setCalculatedAllPositions(data.allCalculatedPositions);
      }

      if (Array.isArray(data.widgetsData.cashBalance)) {
        this.positionsFacade.setCalculatedCashBalance(data.widgetsData.cashBalance);
      }
      if (data.widgetsData.summaryData) {
        this.positionsFacade.setCalculatedFilteredByAccountSummaryData(
          data.widgetsData.summaryData
        );
      }

      if (data.rebalance.rebalanceUpdates.length) {
        const rebalanceToUpdate: Update<PmsPosition>[] = data.rebalance.rebalanceUpdates.map(
          (rebalanceUpdate): Update<PmsPosition> => {
            let hasValidationError = false;

            if (
              rebalanceUpdate.changes.tgtpct != null &&
              rebalanceUpdate.changes.rebalanceBy?.isFirstTimeRebalance === false &&
              rebalanceUpdate.changes.rebalanceBy?.key === REBALANCE_COLUMN_KEYS.rebalancequantity
            ) {
              const isLong = rebalanceUpdate.changes.direction === PmsDirection.LONG;
              const value = rebalanceUpdate.changes.tgtpct;

              if (isLong && (value > 30 || value < 0)) {
                hasValidationError = true;
                this.toast.warning(
                  'Rebalance target percentage cannot be less than 0% and more than 30%'
                );
              } else if (!isLong && (value < -30 || value > 0)) {
                hasValidationError = true;
                this.toast.warning(
                  'Rebalance target percentage cannot be less than -30% and more than 0%'
                );
              }
            }
            if (hasValidationError) {
              return {
                ...rebalanceUpdate,
                changes: {
                  ...rebalanceUpdate.changes,
                  rebalanceBy: undefined,
                  tgtpct: undefined,
                  rebalancequantity: undefined,
                  rebalancecost: undefined
                }
              };
            }

            return {
              ...rebalanceUpdate,
              changes: {
                ...rebalanceUpdate.changes,
                rebalanceBy: rebalanceUpdate.changes.rebalanceBy
                  ? {
                      ...rebalanceUpdate.changes.rebalanceBy,
                      isFirstTimeRebalance: false
                    }
                  : undefined
              }
            };
          }
        );

        rebalanceToUpdate.forEach(rebalanceUpdate => {
          if (rebalanceUpdate.id) {
            if (rebalanceUpdate.changes.rebalanceBy) {
              this.rebalanceConfigFacade.addStagedRowsForRebalance(rebalanceUpdate.id.toString());
            } else {
              this.rebalanceConfigFacade.resetStagedRowsForRebalance([
                rebalanceUpdate.id.toString()
              ]);
            }
          }
        });
        this.positionsFacade.updateManyPositions(rebalanceToUpdate);
      }
      this.positionsFacade.setCalculatedNavCurrentMap(data.navCurrentMap);
    };
  }

  private handleRealtimePriceEvents(): void {
    this.realtimePriceEventsDataWorker.onmessage = ({
      data: { positions, filtersValues }
    }): void => {
      this.isLogMode &&
        console.log('realtimePriceEventsDataWorker [END]', {
          data: {
            positions,
            filtersValues
          },
          date: new Date()
        });

      if (positions.length) {
        this.positionsFacade.updateManyPositions(positions);
        this.updateColumnsAllFilterValues(filtersValues);
      }
    };
  }

  private buildColDefs(
    columns: PmsColumn[],
    accounts: Account[],
    aum: Aum[],
    currentUserIsDev: boolean
  ): PmsGridColDef[] {
    const rebalanceColumnsNames: string[] = Object.values(REBALANCE_COLUMN_KEYS);
    let largestViewOrder = Math.max(
      ...columns.map(c =>
        rebalanceColumnsNames.includes(c.name.toLowerCase()) ? -1 : (c.viewOrder ?? -1)
      )
    );

    const sorted = sortBy(
      columns.map(column => ({
        ...column,
        viewOrder: rebalanceColumnsNames.includes(column.name.toLowerCase())
          ? rebalanceColumnsNames.indexOf(column.name.toLowerCase()) + 200 // Number larger than columns amount
          : (column.viewOrder ?? ++largestViewOrder)
      })),
      'viewOrder'
    ).filter(col => !col.developerAccess || currentUserIsDev);

    const cols = sorted.map(column => this.buildSingleColDef(column));
    return [...cols, ...this.appendAccountAumColumns(accounts, aum), ...this.appendActionsColumn()];
  }

  private buildSingleColDef(column: PmsColumn): PmsGridColDef {
    const { caption, name, isAggregating, type, decimals, includedInDefaultPreset, isPercentage } =
      column;
    const colId = name.toLowerCase();
    const colDef: PmsGridColDef = {
      field: colId,
      colId: colId,
      headerName: caption ?? name,
      headerTooltip: caption ?? name,
      hide: !includedInDefaultPreset,
      aggFunc: getColumnAggregations(type, colId, isAggregating)
    };
    if (colId === 'direction') {
      colDef.cellClass = 'text-capitalize';
      colDef.valueFormatter = ({ value, node }): string =>
        node?.group ? value : value?.toLowerCase();
    }

    const rebalanceProps = this.processRebalanceColDefs(colDef);
    // TODO create / reuse cellRenderers
    switch (type) {
      case 'text':
        colDef.cellRenderer = (params: ICellRendererParams): string => textCellRenderer(params);
        break;
      case 'boolean':
        colDef.cellRenderer = (params: ICellRendererParams): string =>
          checkmarkCellRenderer(params);
        break;
      case 'currency':
      case 'numeric':
      case 'stream':
      case 'calculation':
        if (colId === 'mark') {
          colDef.cellRendererSelector = (
            params: ICellRendererParams
          ): CellRendererSelectorResult => ({
            component: CellRendererDistinctValueComponent,
            params: {
              customValueFormatter:
                params.data?.securitytype === 'GOVT' || params.data?.securitytype === 'INTRATE'
                  ? (value: number, { ticker, securitytype }: PmsPosition): string =>
                      new FutureAssetValueFormatter(value, ticker, securitytype).getFormattedValue()
                  : undefined
            }
          });
        } else if (
          [
            'pnltotalpct',
            'totaldaygainloss',
            'changepercent',
            'unrealizeddaygainloss',
            'realizeddaygainloss'
          ].includes(colId)
        ) {
          colDef.cellRenderer = 'positiveNegativeColorized';
          colDef.valueFormatter = ({
            value
          }: {
            value: number | string | null | undefined;
          }): string => {
            if (value == null || (value === '' && !isAggregating)) {
              return '';
            }
            return decimalFormatter(
              {
                decimalPlaces: decimals ?? undefined,
                isPercentage: isPercentage ?? undefined
              },
              { value: +value }
            ).toString();
          };
        } else if (['daycost', 'pricesodlocal'].includes(colId)) {
          colDef.cellRendererSelector = (
            params: ICellRendererParams<PmsPosition>
          ): CellRendererSelectorResult | undefined => {
            if (params.data?.securitytype === 'GOVT' || params.data?.securitytype === 'INTRATE') {
              return {
                component: (params: ICellRendererParams<PmsPosition>): string =>
                  new FutureAssetValueFormatter(
                    params.value,
                    params.data?.ticker,
                    params.data?.securitytype
                  ).getFormattedValue()
              };
            }
            return {
              component: (
                params: ICellRendererParams & {
                  formatOptions: NumberFormatOptions;
                }
              ): string => numericCellRenderer(params),
              params: {
                formatOptions: {
                  isCurrency: colId.includes('currency'),
                  decimalPlaces: decimals ?? 0,
                  isAggregating,
                  isPercentage
                }
              }
            };
          };
        } else {
          colDef.cellRenderer = (
            params: ICellRendererParams & {
              formatOptions: NumberFormatOptions;
            }
          ): string => numericCellRenderer(params);
          colDef.cellRendererParams = {
            formatOptions: {
              isCurrency: colId.includes('currency'),
              decimalPlaces: decimals ?? 0,
              isAggregating,
              isPercentage
            }
          };
        }
        colDef.filter = false;
        colDef.cellClass = 'text-right';
        break;
      case 'date':
      case 'datetime':
        colDef.filter = 'date';
        colDef.valueFormatter = (params: ValueFormatterParams): string =>
          dateFormatter(params, type === 'datetime' ? 'HH:mm:SS' : 'MM/dd/y', 'en-US');
        break;
    }
    return { ...colDef, ...rebalanceProps };
  }

  private appendAccountAumColumns(accounts: Account[], allAums: Aum[]): PmsGridColDef[] {
    return allAums.map(aum => {
      const accountCode = accounts.find(acc => +acc.id === aum.accountId)?.accountCode ?? '';
      const caption = `${accountCode.toUpperCase()} MV%`;
      const name = `MV${aum.accountId}`;
      return {
        field: name,
        colId: name.toLowerCase(),
        cellClass: 'text-right',
        headerName: caption,
        headerTooltip: caption,
        hide: allAums.length > 5 || allAums.length === 1,
        filter: false,
        cellRenderer: (
          params: ICellRendererParams & {
            formatOptions: NumberFormatOptions;
          }
        ) => numericCellRenderer(params),
        cellRendererParams: {
          formatOptions: {
            isCurrency: false,
            decimalPlaces: 2,
            isAggregating: true,
            isPercentage: true,
            emptyOnZeroValue: false,
            emptyOnNullValue: true
          }
        }
      };
    });
  }

  private appendActionsColumn(): PmsGridColDef[] {
    return [
      {
        field: 'actionbutton',
        colId: 'actionbutton',
        headerName: 'Action',
        maxWidth: 65,
        initialPinned: 'left',
        lockPosition: 'left',
        lockPinned: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        suppressMovable: false,
        suppressColumnsToolPanel: true,
        suppressSizeToFit: true,
        enableRowGroup: false,
        cellClass: 'p-0 d-flex justify-content-center align-items-center',
        cellRendererSelector: ({
          context,
          data,
          node
        }: ICellRendererParams): Record<string, unknown> => {
          if (node.group || node.rowPinned || node.footer || data.assettype === 'CASH') {
            return { component: CellEmptyComponent };
          } else {
            return {
              component: CellInlineSvgComponent,
              params: {
                onClick: () => context.componentParent?.actionBtnClicked(data)
              }
            };
          }
        },
        valueGetter: () => `
        <svg class='cursor-pointer text-color' width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path id='Icon' fill-rule='evenodd' clip-rule='evenodd' d='M6.26859 1.55479C5.69483 0.981033 6.10119 0 6.9126 0H11.0892C11.5922 0 12 0.407768 12 0.910776V5.0874C12 5.89881 11.019 6.30517 10.4452 5.73141L9.064 4.35021L4.35021 9.064L5.73141 10.4452C6.30517 11.019 5.89881 12 5.0874 12H0.910776C0.407768 12 0 11.5922 0 11.0892V6.9126C0 6.10119 0.981035 5.69483 1.55479 6.26859L2.936 7.64979L7.64979 2.936L6.26859 1.55479Z' fill='currentColor'/>
        </svg>
        `
      }
    ];
  }

  private processRebalanceColDefs(colDef: PmsGridColDef): PmsGridColDef {
    const additionalProps = {} as PmsGridColDef;
    if (Object.values(REBALANCE_COLUMN_KEYS).includes(colDef.colId as REBALANCE_COLUMN_KEYS)) {
      additionalProps.pinned = 'right';
      additionalProps.lockPinned = true;
      additionalProps.lockPosition = 'right';
      additionalProps.sortable = false;
      additionalProps.suppressMovable = true;
      additionalProps.suppressHeaderMenuButton = true;
      additionalProps.enableRowGroup = false;

      additionalProps.cellRenderer = (
        params: ICellRendererParams & {
          formatOptions: NumberFormatOptions;
        }
      ): string => numericCellRenderer(params);
      additionalProps.cellRendererParams = {
        formatOptions: {
          isCurrency: false,
          isAggregating: false,
          decimalPlaces: colDef.colId === REBALANCE_COLUMN_KEYS.tgtpct ? 2 : 0,
          isPercentage: colDef.colId === REBALANCE_COLUMN_KEYS.tgtpct,
          emptyOnZeroValue: false,
          emptyOnNullValue: true
        }
      };
      additionalProps.filter = 'agNumberColumnFilter';
      if (
        colDef.colId === REBALANCE_COLUMN_KEYS.rebalancequantity ||
        colDef.colId === REBALANCE_COLUMN_KEYS.tgtpct
      ) {
        additionalProps.cellStyle = { color: 'blue' };
        additionalProps.editable = ({ node }): boolean =>
          node?.data?.assettype !== CASH_ASSETTYPE &&
          node?.data?.mark !== 0 &&
          node?.data?.mark != null;
      }
    }
    if (colDef.colId === REBALANCE_COLUMN_KEYS.tgtpct) {
      additionalProps.onCellValueChanged = (params: NewValueParams<PmsPosition>): void => {
        this.handleRebalanceCellChange(params, REBALANCE_COLUMN_KEYS.tgtpct);
      };
    }
    if (colDef.colId === REBALANCE_COLUMN_KEYS.rebalancequantity) {
      additionalProps.onCellValueChanged = (params): void => {
        this.handleRebalanceCellChange(params, REBALANCE_COLUMN_KEYS.rebalancequantity);
      };
    }
    return additionalProps;
  }

  private handleRebalanceCellChange(
    params: NewValueParams<PmsPosition>,
    key: REBALANCE_COLUMN_KEYS.tgtpct | REBALANCE_COLUMN_KEYS.rebalancequantity
  ): void {
    let validationSuccess = true;
    const rowId = params.data.id;
    const val = params.newValue === '' || params.newValue == null ? undefined : +params.newValue;
    const prevVal = +params.oldValue;

    let value =
      val != null ? (isNaN(val) || Math.abs(val) === Infinity ? undefined : val) : undefined;

    const valuesToUpdateInPosition: Partial<PmsPosition> = rowId
      ? {
          rebalanceBy: value != null ? { rowId, key, isFirstTimeRebalance: true } : undefined
        }
      : {};
    if (value == null) {
      valuesToUpdateInPosition.tgtpct = undefined;
      valuesToUpdateInPosition.rebalancequantity = undefined;
      valuesToUpdateInPosition.rebalancecost = undefined;
    } else {
      value = key === REBALANCE_COLUMN_KEYS.tgtpct ? GlobalNumberRound(value, 2) : value;
      const isLong = params.data.direction === PmsDirection.LONG;

      // tgtPct can't be more than 30%
      if (key === REBALANCE_COLUMN_KEYS.tgtpct) {
        if (isLong && (value > 30 || value < 0)) {
          validationSuccess = false;
          this.toast.warning(
            'Rebalance target percentage cannot be less than 0% and more than 30%'
          );
        } else if (!isLong && (value < -30 || value > 0)) {
          validationSuccess = false;
          this.toast.warning(
            'Rebalance target percentage cannot be less than -30% and more than 0%'
          );
        }
      }

      // rebalancequantity can't be less than current position (you can't sell more than you have)
      if (key === REBALANCE_COLUMN_KEYS.rebalancequantity) {
        if (isLong && value < params.data.currentposition * -1) {
          validationSuccess = false;
          this.toast.warning('Rebalance quantity cannot be less than current position');
        } else if (!isLong && value > Math.abs(params.data.currentposition)) {
          validationSuccess = false;
          this.toast.warning('Rebalance quantity cannot cover more than current position');
        }
      }
    }

    if (rowId && value !== prevVal && validationSuccess) {
      this.positionsFacade.updatePosition(rowId, {
        [key]: value,
        ...valuesToUpdateInPosition
      });
      this.positionsFacade.updateCalculatedGridPosition(rowId, {
        [key]: value
      });

      if (valuesToUpdateInPosition.rebalanceBy == null) {
        this.rebalanceConfigFacade.resetStagedRowsForRebalance([rowId]);
      }
    }
  }
}
