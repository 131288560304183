import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getLoadableEntities$ } from '@pinnakl/shared/util-helpers';
import { loadAssetTypes } from './asset-types.actions';
import { AssetTypesState } from './asset-types.models';
import { assetTypesQuery } from './asset-types.selectors';

@Injectable({ providedIn: 'root' })
export class AssetTypesFacadeService {
  assetTypes$ = getLoadableEntities$(
    this.store.select(assetTypesQuery.getAssetTypes),
    this.store.select(assetTypesQuery.getAssetTypesLoaded),
    this.store.select(assetTypesQuery.getAssetTypesLoading),
    this.loadAssetTypes.bind(this)
  );

  constructor(private readonly store: Store<AssetTypesState>) {}

  loadAssetTypes(): void {
    this.store.dispatch(loadAssetTypes());
  }
}
