import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { Aum } from '@pinnakl/poems/aum/domain';
import { Observable } from 'rxjs';

@Injectable()
export class AumApiService {
  constructor(private readonly wsp: WebServiceProvider) {}

  getAums(): Observable<Aum[]> {
    return this.wsp.get<Aum[]>({
      endpoint: 'v4/navs/sod'
    });
  }
}
