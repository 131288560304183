import { Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';

// todo: delete asap when new settings page will be released
@Injectable({
  providedIn: 'root'
})
export class BlockedIpsService {
  private readonly _crm_blocked_ips = 'entities/crm_blocked_ips';

  constructor(private readonly wsp: WebServiceProvider) {}

  async getBlockedIps() {
    return await this.wsp.getHttp<any[]>({
      endpoint: 'entities/crm_blocked_ips',
      optionsParams: {
        fields: ['id', 'ipaddress']
      }
    });
  }

  async postBlockedIps(payload) {
    return await this.wsp.postHttp<any[]>({
      endpoint: this._crm_blocked_ips,
      body: payload
    });
  }

  async removeBlockedIps(ipId) {
    return await this.wsp.deleteHttp<any[]>({
      endpoint: `${this._crm_blocked_ips}/${ipId}`
    });
  }
}
