import { inject, Injectable } from '@angular/core';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { SecurityMarket, SecurityMarketDetails } from '@pinnakl/securities/domain';
import { V4_ENDPOINT } from '@pinnakl/shared/constants';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';

export type SecurityMarketsFilter = {
  securityId: number | null;
};

@Injectable({
  providedIn: 'root'
})
export class SecurityMarketsApiService {
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async loadSecurityMarkets(filter: SecurityMarketsFilter): Promise<SecurityMarket[]> {
    try {
      return await firstValueFrom(this.getSecurityMarkets(filter));
    } catch (error) {
      this.toast.warning('Unable to load security markets.' + getErrorMessage('', error));
      throw error;
    }
  }

  async loadSecurityMarketDetails(securityMarketId: number): Promise<SecurityMarketDetails> {
    try {
      return await firstValueFrom(
        this.wsp.get<SecurityMarketDetails>({
          endpoint: `${V4_ENDPOINT}security-markets/${securityMarketId}`
        })
      );
    } catch (error) {
      this.toast.warning('Unable to load security market details.' + getErrorMessage('', error));
      throw error;
    }
  }

  async loadAllSecurityMarketDetails(): Promise<SecurityMarketDetails[]> {
    try {
      return await firstValueFrom(
        this.wsp.get<SecurityMarketDetails[]>({
          endpoint: `${V4_ENDPOINT}security-markets/batch`
        })
      );
    } catch (error) {
      this.toast.warning('Unable to load security market details.' + getErrorMessage('', error));
      throw error;
    }
  }

  private getSecurityMarkets(filter: SecurityMarketsFilter): Observable<SecurityMarket[]> {
    return this.wsp.get<SecurityMarket[]>({
      endpoint: `${V4_ENDPOINT}securities/${filter.securityId}/markets`
    });
  }
}
