import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { SecurityAssetType } from '@pinnakl/securities/domain';
import { setLoadedAndLoadingFields } from '@pinnakl/shared/util-helpers';
import {
  assetTypesLoadFailure,
  assetTypesLoadSuccess,
  loadAssetTypes
} from './asset-types.actions';
import { AssetTypesState } from './asset-types.models';

export const adapter: EntityAdapter<SecurityAssetType> = createEntityAdapter<SecurityAssetType>();

export const initialState: AssetTypesState = adapter.getInitialState(
  setLoadedAndLoadingFields(false, false)
);

export const assetTypesReducer = createReducer(
  initialState,
  on(loadAssetTypes, state => ({ ...state, ...setLoadedAndLoadingFields(false, true) })),
  on(assetTypesLoadSuccess, (state, { assetTypes }) =>
    adapter.setAll(assetTypes, {
      ...state,
      ...setLoadedAndLoadingFields(true, false)
    })
  ),
  on(assetTypesLoadFailure, state => ({ ...state, ...setLoadedAndLoadingFields(false, false) }))
);
