import { createAction, props } from '@ngrx/store';
import { Account } from '@pinnakl/poems/accounts/domain';
import { FolderComment } from '@pinnakl/poems/folders/domain';
import {
  ExposureSummaryInfo,
  MarkOverrideData
} from '@pinnakl/poems/modals/security-details/domain';
import { SecurityPositionTimeItem, SecurityPriceTimeItem } from '@pinnakl/poems/timeseries/domain';
import { SecurityMarketDetails } from '@pinnakl/securities/domain';

export enum SecurityDetailsModalActions {
  SelectAccounts = '[Security details modal] Select accounts',
  SelectFolderId = '[Security details modal] Select folder id',
  FolderCommentsLoaded = '[Security details modal] Folder comments loaded',
  SetActiveTicker = '[Security details modal] Set active ticker',
  SetActiveSecurityMarketId = '[Security details modal] Set active security market id',
  SetActiveUnderlyingSecurityId = '[Security details modal] Set active underlying security id',
  ResetState = '[Security details modal] Reset state',
  SetTimeseriesLoading = '[Security details modal] Set timeseries loading',
  SetTimeseriesPrices = '[Security details modal] Set timeseries prices',
  SetTimeseriesPositions = '[Security details modal] Set timeseries positions',
  SetExposureSummaryInfo = '[Security details modal] Set exposure summary info',
  SetMarkOverrideData = '[Security details modal] Set mark override data',
  SetSecurity = '[Security details modal] Set security'
}

export const selectAccounts = createAction(
  SecurityDetailsModalActions.SelectAccounts,
  props<{ accounts: Account[] }>()
);

export const selectFolderId = createAction(
  SecurityDetailsModalActions.SelectFolderId,
  props<{ folderId: string }>()
);

export const securityDetailsModalFolderCommentsLoaded = createAction(
  SecurityDetailsModalActions.FolderCommentsLoaded,
  props<{ folderComments?: FolderComment[] }>()
);

export const setActiveTicker = createAction(
  SecurityDetailsModalActions.SetActiveTicker,
  props<{ ticker: string }>()
);

export const setActiveSecurityMarketId = createAction(
  SecurityDetailsModalActions.SetActiveSecurityMarketId,
  props<{ securityMarketId: string }>()
);

export const setActiveUnderlyingSecurityId = createAction(
  SecurityDetailsModalActions.SetActiveUnderlyingSecurityId,
  props<{ underlyingSecurityId: string }>()
);

export const resetState = createAction(SecurityDetailsModalActions.ResetState);

export const setTimeseriesLoading = createAction(
  SecurityDetailsModalActions.SetTimeseriesLoading,
  props<{ loading: boolean }>()
);
export const setTimeseriesPrices = createAction(
  SecurityDetailsModalActions.SetTimeseriesPrices,
  props<{ prices: SecurityPriceTimeItem[] }>()
);
export const setTimeseriesPositions = createAction(
  SecurityDetailsModalActions.SetTimeseriesPositions,
  props<{ positions: SecurityPositionTimeItem[] }>()
);
export const setExposureSummaryInfo = createAction(
  SecurityDetailsModalActions.SetExposureSummaryInfo,
  props<{ exposureSummaryInfo: ExposureSummaryInfo }>()
);
export const setMarkOverrideData = createAction(
  SecurityDetailsModalActions.SetMarkOverrideData,
  props<{ markOverrideData?: MarkOverrideData }>()
);
export const setSecurity = createAction(
  SecurityDetailsModalActions.SetSecurity,
  props<{ security: SecurityMarketDetails }>()
);
