import { Injectable } from '@angular/core';

import { WebServiceProvider } from '@pinnakl/core/web-services';
import { AssetType, Security, SecurityFromApi } from '@pinnakl/shared/types';
import moment from 'moment';
import { Observable, from } from 'rxjs';

export interface OptionLookupChainExpiration {
  callStrikes: number[];
  putStrikes: number[];
  expirationDate: string;
}

export interface OptionLookupChainResponse {
  expirations: OptionLookupChainExpiration[];
}

@Injectable()
export class SecurityService {
  private readonly securitiesV4Endpoint = 'v4/entities/securities';
  private readonly _assetTypesEndpoint = 'entities/asset_types';
  private readonly _securityOptionLookupEndpoint = 'v3/entities/option_chain_lookup';
  private readonly _securityIdentifiersTickerSearchEndpoint = 'entities/idc_ticker_search';

  private _assetTypes: AssetType[];

  constructor(private readonly wsp: WebServiceProvider) {}

  getCusipsByTicker(identifier: string): Observable<any> {
    return from(
      this.wsp.getHttp({
        endpoint: this._securityIdentifiersTickerSearchEndpoint,
        optionsParams: { filters: [{ key: 'ticker', type: 'EQ', value: [identifier] }] }
      })
    );
  }

  getAssetTypes(): Promise<AssetType[]> {
    if (this._assetTypes) {
      return Promise.resolve(this._assetTypes);
    }

    return this.wsp
      .getHttp<any[]>({
        endpoint: this._assetTypesEndpoint,
        optionsParams: {
          fields: ['id', 'assettype', 'daysforsettlement', 'multiplier']
        }
      })
      .then(assetTypes => {
        this._assetTypes = assetTypes.map(x => {
          return this.formatAssetType(x);
        });
        return this._assetTypes;
      });
  }

  public formatAssetType(assetTypeFromApi: any): AssetType {
    return new AssetType(
      +assetTypeFromApi.id,
      assetTypeFromApi.assettype,
      +assetTypeFromApi.daysforsettlement,
      assetTypeFromApi.multiplier
    );
  }

  async saveSecurityAutomatically(
    identifierType: string,
    identifierValue: string
  ): Promise<Security> {
    try {
      const securityFromApi = await this.wsp.postHttp<SecurityFromApi>({
        endpoint: `${this.securitiesV4Endpoint}/import`,
        body: {
          identifierType: identifierType,
          identifierValue: identifierValue
        }
      });
      return this.formatSecurity(securityFromApi);
    } catch (e) {
      if (e.message && e.message.toLowerCase().includes('mandatory fields')) {
        throw { clientMessage: e.message };
      }
      throw e;
    }
  }

  public optionChainLookup(ticker: string, callPut: string): Observable<OptionLookupChainResponse> {
    return this.wsp.post<OptionLookupChainResponse>({
      endpoint: this._securityOptionLookupEndpoint,
      body: {
        ticker,
        callPut
      }
    });
  }

  private formatSecurity(entity: SecurityFromApi): Security {
    if (!entity) {
      return;
    }
    const assetTypeId = +entity.assettypeid,
      currencyId = +entity.currencyid,
      dataSourceId = +entity.datasource,
      id = +entity.id,
      multiplier = +entity.multiplier,
      organizationId = +entity.organization_id,
      securityTypeId = +entity.sectypeid,
      principalFactor = parseFloat(entity.principal_factor),
      initialMargin = parseFloat(entity.initialmargin),
      maintenanceMargin = parseFloat(entity.maintenancemargin),
      maturityMoment = moment(entity.maturity, 'MM/DD/YYYY');

    return new Security(
      entity.assettype,
      !isNaN(assetTypeId) ? assetTypeId : null,
      entity.countryofincorporation,
      entity.countryofrisk,
      +entity.countryofquotationid,
      entity.countryofquotationcode,
      entity.countryofquotationname,
      entity.countryofincorporationcode,
      entity.countryofincorporationname,
      entity.currency,
      !isNaN(currencyId) ? currencyId : null,
      entity.cusip,
      !isNaN(dataSourceId) ? dataSourceId : null,
      entity.description,
      !isNaN(id) ? id : null,
      entity.isin,
      entity.loanid,
      entity.manualpricingindicator === 'True',
      entity.moodyrating,
      !isNaN(multiplier) ? multiplier : null,
      entity.opracode,
      !isNaN(organizationId) ? organizationId : null,
      entity.organizationname,
      entity.organization_status_descr,
      entity.organization_status_id,
      entity.organizationticker,
      entity.privateindicator === 'True',
      entity.sandprating,
      entity.sector,
      entity.sectype,
      entity.sectypedescription,
      !isNaN(securityTypeId) ? securityTypeId : null,
      entity.sedol,
      entity.ticker,
      !isNaN(principalFactor) ? principalFactor : null,
      !isNaN(initialMargin) ? initialMargin : null,
      !isNaN(maintenanceMargin) ? maintenanceMargin : null,
      maturityMoment.isValid() ? maturityMoment.toDate() : null,
      entity.underlyingsecid ? +entity.underlyingsecid : null,
      +entity.sharesoutstanding,
      entity.pricingsourcename,
      entity.securitypricingid,
      +entity.securitymarketid,
      +entity.marketid,
      +entity.id
    );
  }
}
