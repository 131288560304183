import { Injectable } from '@angular/core';
import { getLoadableEntities$ } from '@pinnakl/shared/util-helpers';

import { Store } from '@ngrx/store';
import { loadSecurityTypes } from './security-types.actions';
import { SecurityTypesState } from './security-types.models';
import { securityTypesQuery } from './security-types.selectors';

@Injectable({ providedIn: 'root' })
export class SecurityTypesFacadeService {
  securityTypes$ = getLoadableEntities$(
    this.store.select(securityTypesQuery.getSecurityTypes),
    this.store.select(securityTypesQuery.getSecurityTypesLoaded),
    this.store.select(securityTypesQuery.getSecurityTypesLoading),
    this.loadAssetTypes.bind(this)
  );

  constructor(private readonly store: Store<SecurityTypesState>) {}

  loadAssetTypes(): void {
    this.store.dispatch(loadSecurityTypes());
  }
}
