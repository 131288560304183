import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { SecurityMarket, SecurityMarketDetails } from '@pinnakl/securities/domain';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { SecurityMarketsApiService, SecurityMarketsFilter } from './security-markets-api.service';

interface SecurityMarketsStoreState {
  securityMarketsEntities: SecurityMarket[];
  securityMarketsEntitiesLoaded: boolean;
  securityMarketsEntitiesLoading: boolean;
  securityMarketDetails: null | SecurityMarketDetails;
  securityMarketDetailsLoaded: boolean;
  securityMarketDetailsLoading: boolean;
  allSecurityMarketDetails: SecurityMarketDetails[];
  allSecurityMarketsEntitiesLoaded: boolean;
  allSecurityMarketsEntitiesLoading: boolean;
}

const securityMarketsStoreKey = 'securityMarkets';
const securityMarketsStoreInitialState: SecurityMarketsStoreState = {
  securityMarketsEntities: [],
  securityMarketsEntitiesLoaded: false,
  securityMarketsEntitiesLoading: false,
  securityMarketDetails: null,
  securityMarketDetailsLoaded: false,
  securityMarketDetailsLoading: false,
  allSecurityMarketDetails: [],
  allSecurityMarketsEntitiesLoaded: false,
  allSecurityMarketsEntitiesLoading: false
};

export const SecurityMarketsStore = signalStore(
  { providedIn: 'root' },
  withDevtools(securityMarketsStoreKey),
  withState(securityMarketsStoreInitialState),
  withMethods((store, api = inject(SecurityMarketsApiService)) => ({
    loadSecurityMarketsEntities: async (
      filters: SecurityMarketsFilter,
      forceRefetch = false
    ): Promise<SecurityMarket[]> => {
      if (store.securityMarketsEntitiesLoaded() && !forceRefetch) {
        return store.securityMarketsEntities();
      }
      try {
        patchState(store, {
          securityMarketsEntitiesLoaded: false,
          securityMarketsEntitiesLoading: true
        });
        const securityMarkets = await api.loadSecurityMarkets(filters);
        patchState(store, {
          securityMarketsEntities: securityMarkets,
          securityMarketsEntitiesLoaded: true
        });
        return securityMarkets;
      } finally {
        patchState(store, { securityMarketsEntitiesLoading: false });
      }
    },
    loadSecurityMarketDetails: async (securityMarketId: number): Promise<SecurityMarketDetails> => {
      try {
        patchState(store, {
          securityMarketDetailsLoading: true,
          securityMarketsEntitiesLoaded: false
        });
        const securityMarketDetails = await api.loadSecurityMarketDetails(securityMarketId);
        patchState(store, { securityMarketDetails, securityMarketsEntitiesLoaded: true });
        return securityMarketDetails;
      } finally {
        patchState(store, { securityMarketDetailsLoading: false });
      }
    },
    loadAllSecuritiesMarketDetails: async (
      forceRefetch = false
    ): Promise<SecurityMarketDetails[]> => {
      if (store.allSecurityMarketsEntitiesLoaded() && !forceRefetch) {
        return store.allSecurityMarketDetails();
      }
      try {
        patchState(store, {
          allSecurityMarketsEntitiesLoading: true,
          allSecurityMarketsEntitiesLoaded: false
        });
        const allSecurityMarketDetails = await api.loadAllSecurityMarketDetails();
        patchState(store, { allSecurityMarketDetails, allSecurityMarketsEntitiesLoaded: true });
        return allSecurityMarketDetails;
      } finally {
        patchState(store, { allSecurityMarketsEntitiesLoading: false });
      }
    }
  }))
);
