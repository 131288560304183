import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FolderComment } from '@pinnakl/poems/folders/domain';
import {
  ExposureSummaryInfo,
  MarkOverrideData
} from '@pinnakl/poems/modals/security-details/domain';
import { SecurityPositionTimeItem, SecurityPriceTimeItem } from '@pinnakl/poems/timeseries/domain';
import { SecurityMarketDetails } from '@pinnakl/securities/domain';
import { take } from 'rxjs';
import {
  resetState,
  securityDetailsModalFolderCommentsLoaded,
  selectAccounts,
  setActiveSecurityMarketId,
  setActiveTicker,
  setActiveUnderlyingSecurityId,
  setExposureSummaryInfo,
  setMarkOverrideData,
  setSecurity,
  setTimeseriesLoading,
  setTimeseriesPositions,
  setTimeseriesPrices
} from './security-details-modal.actions';
import { SecurityDetailsModalState } from './security-details-modal.models';
import { securityDetailsModalStateQuery } from './security-details-modal.selectors';

@Injectable({
  providedIn: 'root'
})
export class SecurityDetailsModalFacadeService {
  markOriginalOverriddenValue$ = this.store.select(
    securityDetailsModalStateQuery.getMarkOriginalOverriddenValue
  );
  exposureSummaryInfo$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityExposureSummaryInfo
  );
  timeseriesLoading$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalTimeseriesLoading
  );
  timeseriesPrices$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalTimeseriesPrices
  );
  timeseriesPositions$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalTimeseriesPositions
  );

  selectedAccounts$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalSelectedAccounts
  );
  folderComments$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalFolderComments
  );
  activeTicker$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalActiveTicker
  );
  activeSecurityMarketId$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalActiveSecurityMarketId
  );
  activeUnderlyingSecurityId$ = this.store.select(
    securityDetailsModalStateQuery.getSecurityDetailsModalActiveUnderlyingSecurityId
  );
  markOverrideData$ = this.store.select(securityDetailsModalStateQuery.getSecurityMarkOverrideData);
  securityObject$ = this.store.select(securityDetailsModalStateQuery.getSecurityObject);

  constructor(private readonly store: Store<SecurityDetailsModalState>) {}

  setSelectedAccounts(accounts: any[]): void {
    this.store.dispatch(selectAccounts({ accounts }));
  }

  setFolderComments(folderComments?: FolderComment[]): void {
    this.store.dispatch(securityDetailsModalFolderCommentsLoaded({ folderComments }));
  }

  setActiveTicker(ticker: string): void {
    this.store.dispatch(setActiveTicker({ ticker }));
  }

  setActiveSecurityMarketId(securityMarketId: string): void {
    this.store.dispatch(setActiveSecurityMarketId({ securityMarketId }));
  }

  setActiveUnderlyingSecurityId(underlyingSecurityId: string): void {
    this.store.dispatch(setActiveUnderlyingSecurityId({ underlyingSecurityId }));
  }

  reset(): void {
    this.store.dispatch(resetState());
  }

  setTimeseriesLoading(loading: boolean): void {
    this.timeseriesLoading$
      .pipe(take(1))
      .subscribe(
        isLoading => isLoading !== loading && this.store.dispatch(setTimeseriesLoading({ loading }))
      );
  }

  setTimeseriesPrices(prices: SecurityPriceTimeItem[]): void {
    this.store.dispatch(setTimeseriesPrices({ prices }));
  }

  setTimeseriesPositions(positions: SecurityPositionTimeItem[]): void {
    this.store.dispatch(setTimeseriesPositions({ positions }));
  }

  setExposureSummaryInfo(exposureSummaryInfo: ExposureSummaryInfo): void {
    this.store.dispatch(setExposureSummaryInfo({ exposureSummaryInfo }));
  }

  setMarkOverrideData(markOverrideData?: MarkOverrideData): void {
    this.store.dispatch(setMarkOverrideData({ markOverrideData }));
  }

  setSecurity(security: SecurityMarketDetails): void {
    this.store.dispatch(setSecurity({ security }));
  }
}
