import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { PeekContentComponent, PeekHtmlComponent, PeekTextComponent } from '../components';
import { ConfirmActionModule } from '../confirm-module/confirm-action.module';
import { GridActionButtonComponent } from './action-buttons';
import { CfActionButtonComponent } from './action-buttons/cf-action-button/cf-action-button.component';
import { PNLActionButtonComponent } from './action-buttons/pnl-action-button/pnl-action-button.component';
import { PositionActionButtonCallbackComponent } from './action-buttons/position-action-button-callback/position-action-button-callback.component';
import { PositionActionButtonComponent } from './action-buttons/position-action-button/position-action-button.component';
import { AgGridFilterDistinctValuesComponent } from './ag-grid-filter-distinct/ag-grid-filter-distinct.component';
import { AgPeekContentComponent } from './ag-peek-content/ag-peek-content.component';
import {
  CellRendererCheckmarkComponent,
  CellRendererDistinctValueComponent,
  CellRendererFormattedDateComponent,
  CellRendererPinnedCustomStyleComponent,
  CellRendererTranTypeTagComponent
} from './cell-renderers';
import { ContactsValidationErrorsComponent } from './contacts-validation-errors/contacts-validation-errors.component';
import { FundsColumnContentComponent } from './funds-column-content/funds-column-content.component';
import { FundsColumnsCustomComponentComponent } from './funds-columns-custom-component/funds-columns-custom-component.component';
import { PinnaklGridCheckboxComponent } from './pinnakl-grid-checkbox';
import { PinnaklGridComponent } from './pinnakl-grid/pinnakl-grid.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, ConfirmActionModule, AgGridModule],
  declarations: [
    AgPeekContentComponent,
    PeekHtmlComponent,
    PeekTextComponent,
    PeekContentComponent,
    CfActionButtonComponent,
    ContactsValidationErrorsComponent,
    FundsColumnsCustomComponentComponent,
    FundsColumnContentComponent,
    GridActionButtonComponent,
    PinnaklGridCheckboxComponent,
    PinnaklGridComponent,
    PNLActionButtonComponent,
    PositionActionButtonComponent,
    PositionActionButtonCallbackComponent,
    CellRendererTranTypeTagComponent,
    AgGridFilterDistinctValuesComponent,
    CellRendererPinnedCustomStyleComponent,
    CellRendererFormattedDateComponent,
    CellRendererCheckmarkComponent,
    CellRendererDistinctValueComponent
  ],
  exports: [
    AgGridModule,
    PeekHtmlComponent,
    PeekTextComponent,
    PeekContentComponent,
    CfActionButtonComponent,
    ContactsValidationErrorsComponent,
    FundsColumnsCustomComponentComponent,
    FundsColumnContentComponent,
    GridActionButtonComponent,
    PinnaklGridComponent,
    PNLActionButtonComponent,
    PositionActionButtonComponent,
    PositionActionButtonCallbackComponent,
    CellRendererDistinctValueComponent
  ],
  providers: []
})
export class SharedComponentsModule {}
