import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  inject
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  CurrentUserStore,
  PinnaklModuleNames,
  PresetsService,
  ReportingService,
  filterPresetConfigName
} from '@pinnakl/core/data-providers';
import { PresetModel, ReportParameter, ReportingColumn } from '@pinnakl/shared/types';
import { DateHelpers } from '@pinnakl/shared/util-helpers';
import { PinnaklSpinnerService, PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import moment, { DurationInputArg2 } from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum OMSDateFilter {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  MTD = 'MTD',
  QTD = 'ATD',
  YTD = 'YTD'
}

type ParsedPresetModel = PresetModel & { configValue: any; showDelete: boolean };

@Component({
  selector: 'grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss'],
  animations: [
    trigger('filterVisibleChanged', [
      state('1', style({ transform: 'translateX(0px)', opacity: 1 })),
      state('0', style({ transform: 'translateX(100%)' })),
      transition('* => *', animate('300ms'))
    ])
  ]
})
export class GridFilterComponent implements OnChanges, OnDestroy {
  private readonly currentUserStore = inject(CurrentUserStore);
  private existingFormState: any;
  private filterSubscriptions: Subscription[] = [];
  private selectAllSubscription: Subscription;
  private readonly unsubscribe$ = new Subject<void>();
  private readonly module: PinnaklModuleNames;
  private _filterVisible = false;

  private get startDateFormControl(): AbstractControl {
    return this.form?.controls?.startdate;
  }

  private get endDateFormControl(): AbstractControl {
    return this.form?.controls?.enddate;
  }

  @Input() columns: (ReportingColumn & { dropdownCollection: any[] })[];
  @Input() parameters: ReportParameter[];
  @Input() suppressSelection = false;
  @Input() reportId: number;
  @Input() typeName: string;
  @Output() onApply: EventEmitter<void> = new EventEmitter<void>();
  @Output() onReset: EventEmitter<void> = new EventEmitter<void>();
  public presets: ParsedPresetModel[];
  form: UntypedFormGroup;
  omsDateFiltersForm: UntypedFormGroup;
  toDateFiltersForm: UntypedFormGroup;
  selectPresetForm: UntypedFormGroup;
  isSavePresetsHide = true;
  readonly omsDateFilterEnum = OMSDateFilter;
  readonly previousFiltersControls = [
    { id: 'previousDayRadio', value: this.omsDateFilterEnum.DAY, name: 'Day' },
    { id: 'previousWeekRadio', value: this.omsDateFilterEnum.WEEK, name: 'Week' },
    { id: 'previousMonthRadio', value: this.omsDateFilterEnum.MONTH, name: 'Month' },
    { id: 'previousQuarterRadio', value: this.omsDateFilterEnum.QUARTER, name: 'Quarter' },
    { id: 'previousYearRadio', value: this.omsDateFilterEnum.YEAR, name: 'Year' }
  ];
  readonly toDateFiltersControls = [
    { id: 'monthToDayRadio', value: this.omsDateFilterEnum.MTD, name: 'MTD' },
    { id: 'quarterToDayRadio', value: this.omsDateFilterEnum.QTD, name: 'QTD' },
    { id: 'yearToDayRadio', value: this.omsDateFilterEnum.YTD, name: 'YTD' }
  ];

  get filterVisible(): boolean {
    return this._filterVisible;
  }

  @Input()
  set filterVisible(value: boolean) {
    this._filterVisible = value;
    if (!value && this.form) {
      this.setFiltersandParameterValues();
    }
  }

  get isDatesValid(): boolean {
    // for may doesn't contain any controls. Only when both controls exists - start check
    if (!this.startDateFormControl && !this.endDateFormControl) {
      return false;
    }
    return moment(this.startDateFormControl.value).isSameOrBefore(
      moment(this.endDateFormControl.value)
    );
  }

  get shouldFilterHaveDateRangePresets(): boolean {
    const isOmsOrEms = this._router.url.includes('oms') || this._router.url.includes('ems');
    return (
      isOmsOrEms ||
      (this.parameters?.length === 2 &&
        this.parameters.some(param => param.name.includes('startdate')) &&
        this.parameters.some(param => param.name.includes('enddate')))
    );
  }

  constructor(
    private readonly reportingService: ReportingService,
    private readonly presetsService: PresetsService,
    private readonly _router: Router,
    private readonly _spinner: PinnaklSpinnerService,
    private readonly _fb: UntypedFormBuilder,
    private readonly toastr: PinnaklUIToastMessage
  ) {
    this.module = this._router.url.includes(PinnaklModuleNames.OMS.toLowerCase())
      ? PinnaklModuleNames.OMS
      : PinnaklModuleNames.EMS;

    this.initOmsDateFiltersForms();
    this.initToDateFiltersForms();
    this.watchPresetsControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const columnsChange = changes.columns,
      parametersChange = changes.parameters;
    if (columnsChange || parametersChange) {
      this.setForm();
      this.getPresets();
    }
  }

  onSubmit(): void {
    this.setFiltersandParameterValues();
    this.onApply.emit();
  }

  toggleSelectAll(selectAll: boolean): void {
    const values: any = {};
    if (this.columns) {
      for (const column of this.columns) {
        values[`${column.name}include`] = selectAll;
      }
    }
    this.form.patchValue(values);
  }

  getDropdownValues(column: ReportingColumn & { dropdownCollection: any[] }): void {
    this.setFiltersandParameterValues();
    const reportingColumns = this.columns
      .filter(col => col.name === column.name || col.filters)
      .map(col => {
        const rc = new ReportingColumn();
        rc.name = col.name;
        rc.reportingColumnType = col.reportingColumnType;
        if (col.name === column.name) {
          rc.include = true;
        } else {
          rc.filters = col.filters;
          rc.include = false;
        }
        return rc;
      });
    this.reportingService
      .getDropdownValues(this.reportId, this.parameters, reportingColumns, this.typeName)
      .then(options => (column.dropdownCollection = options))
      .catch(() => {
        column.dropdownCollection = [];
        this.toastr.error('An unexpected error occurred');
      });
  }

  resetAllFilters(): void {
    this.form.reset();
    this.form.patchValue({ selectAll: true });
    this.form.patchValue(this.existingFormState);
    this.omsDateFiltersForm.get('filterDate').setValue(null, { emitEvent: false });
    this.onReset.emit();
  }

  toggleModal(): void {
    this.isSavePresetsHide = !this.isSavePresetsHide;
  }

  public deletePreset(preset: ParsedPresetModel): void {
    this._spinner.spin();
    this.presetsService
      .deletePreset(parseInt(preset.id, 10))
      .then(res => {
        this._spinner.stop();
        return res;
      })
      .then(
        () => {
          this.presets = this.presets.filter(el => el.id !== preset.id);
          this.toastr.success('Preset was deleted');
        },
        (err: HttpErrorResponse) => {
          this.toastr.error('Error while delete Preset');
          console.error('Error while delete Preset', err);
        }
      );
  }

  showPresetConfirmation($event: any, preset: any): void {
    $event.preventDefault();
    this.presets.forEach(el => (el['showDelete'] = false));
    preset.showDelete = true;
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModalAndRefreshData(): void {
    this.isSavePresetsHide = true;
    this.getPresets();
  }

  private clearSubscriptions(): void {
    if (this.selectAllSubscription) {
      this.selectAllSubscription.unsubscribe();
      delete this.selectAllSubscription;
    }
    if (this.filterSubscriptions) {
      this.filterSubscriptions.forEach(fs => fs.unsubscribe());
      this.filterSubscriptions = [];
    }
  }

  private setFiltersandParameterValues(): void {
    const { value: formValue } = this.form;
    if (this.parameters) {
      for (const parameter of this.parameters) {
        parameter.value = formValue[parameter.name];
      }
    }
    if (this.columns) {
      for (const column of this.columns) {
        column.include = this.suppressSelection ? true : formValue[`${column.name}include`];
        if (formValue[column.name]) {
          column.filters = formValue[column.name];
        }
      }
    }
  }

  private setIncludedInReportForColumn(column: ReportingColumn, newValue: any): void {
    const oldValue = this.form.value[column.name],
      values = {};
    if (newValue instanceof Array ? newValue.length === 1 : newValue) {
      values[`${column.name}include`] = false;
    } else if (newValue instanceof Array ? newValue.length === 0 : !newValue) {
      values[`${column.name}include`] = true;
    } else if (oldValue instanceof Array ? oldValue.length === 1 : oldValue) {
      values[`${column.name}include`] = true;
    }
    this.form.patchValue(values);
  }

  private setForm(): void {
    this.clearSubscriptions();
    const group: any = {};
    if (!this.suppressSelection) {
      const selectAll = new UntypedFormControl(true);
      this.selectAllSubscription = selectAll.valueChanges.subscribe(
        this.toggleSelectAll.bind(this)
      );
      group.selectAll = selectAll;
    }
    if (this.parameters) {
      for (const parameter of this.parameters) {
        group[parameter.name] = new UntypedFormControl(parameter.value);
      }
    }
    if (this.columns) {
      for (const column of this.columns) {
        group[column.name] = new UntypedFormControl(column.filters);
        if (!this.suppressSelection) {
          group[`${column.name}include`] = new UntypedFormControl(column.include);
          this.filterSubscriptions.push(
            group[column.name].valueChanges.subscribe(
              this.setIncludedInReportForColumn.bind(this, column)
            )
          );
        }
      }
    }
    this.form = new UntypedFormGroup(group);
    this.existingFormState = this.form.value;
    delete this.existingFormState.selectAll;
  }

  private initOmsDateFiltersForms(): void {
    this.omsDateFiltersForm = this._fb.group({ filterDate: null });
    this.selectPresetForm = this._fb.group({ preset: null });
  }

  private initToDateFiltersForms(): void {
    this.toDateFiltersForm = this._fb.group({ filterDate: null });
  }

  private watchPresetsControls(): void {
    this.omsDateFiltersForm.controls.filterDate.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dateFilter: OMSDateFilter) => {
        this.toDateFiltersForm.controls.filterDate.setValue(null, { emitEvent: false });
        this.updateFormDates(dateFilter);
      });

    this.toDateFiltersForm.controls.filterDate.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dateFilter: OMSDateFilter) => {
        this.omsDateFiltersForm.controls.filterDate.setValue(null, { emitEvent: false });
        this.updateFormDates(dateFilter);
      });

    this.selectPresetForm.controls.preset.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((preset: ParsedPresetModel) => {
        this.setFormFromPreset(preset);
      });
  }

  private setFormFromPreset(preset: ParsedPresetModel): void {
    const presetConfigObject = preset.configValue;
    const presetValuesKeys = Object.keys(presetConfigObject);
    this.form.reset();
    presetValuesKeys.forEach((key: string) => {
      if (key === 'startdate' || key === 'enddate' || key === 'tradedate' || key === 'settledate') {
        this.form.get(key).setValue(new Date(presetConfigObject[key]));
      } else {
        this.form.get(key).setValue(presetConfigObject[key]);
      }
    });
    if (!this.startDateFormControl.value && !this.endDateFormControl.value) {
      this.startDateFormControl.setValue(new Date());
      this.endDateFormControl.setValue(new Date());
    }
  }

  private updateFormDates(dateFilter: OMSDateFilter): void {
    const subtractPeriod = dateFilter.toLowerCase();

    if (dateFilter === OMSDateFilter.DAY) {
      this.startDateFormControl.setValue(DateHelpers.getPreviousBusinessDay());
      this.endDateFormControl.setValue(DateHelpers.getPreviousBusinessDay());
      return;
    }

    if (dateFilter === OMSDateFilter.MTD) {
      this.startDateFormControl.setValue(DateHelpers.getCurrentFirstDayOf('month'));
      return;
    }

    if (dateFilter === OMSDateFilter.QTD) {
      this.startDateFormControl.setValue(DateHelpers.getCurrentFirstDayOf('quarter'));
      return;
    }

    if (dateFilter === OMSDateFilter.YTD) {
      this.startDateFormControl.setValue(DateHelpers.getCurrentFirstDayOf('year'));
      return;
    }

    this.startDateFormControl.setValue(
      moment()
        .subtract(1, subtractPeriod as DurationInputArg2)
        .startOf(subtractPeriod as DurationInputArg2)
        .toDate()
    );

    this.endDateFormControl.setValue(
      moment()
        .subtract(1, subtractPeriod as DurationInputArg2)
        .endOf(subtractPeriod as DurationInputArg2)
        .toDate()
    );
  }

  private getPresets(): void {
    this.presetsService
      .getPresets(this.currentUserStore.currentUser()?.id, filterPresetConfigName, this.module)
      .then(
        (res: PresetModel[]) =>
          (this.presets = res.map(r => ({
            ...r,
            showDelete: false,
            configValue: JSON.parse(r.configvalue)
          }))),
        (err: HttpErrorResponse) => {
          this.toastr.error('Error while get presets');
          console.error('Error while get presets', err);
        }
      );
  }
}
