import { Injectable, isDevMode, NgModule } from '@angular/core';
import { Actions, createEffect, EffectsModule, ofType } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer, StoreModule } from '@ngrx/store';
import { GlobalErrorAction } from '@pinnakl/shared/constants';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { map } from 'rxjs';
import { clearStore } from './clear-store';
import * as fromRouterState from './router';
import { CustomRouterStateSerializer } from './router';

export interface State {
  router: fromRouterState.RouterState;
}

export function debug(reducer: ActionReducer<any>): any {
  return function (state, action: Action) {
    if (!action.type.includes('@ngrx') && isDevMode()) {
      // console.log('[APP STATE] [Whole state]', _.cloneDeep(state));
      // console.log(`[APP STATE] [${action.type}]`, _.cloneDeep(action));
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<State>[] = [debug, clearStore];

export const reducers: ActionReducerMap<State> = {
  router: fromRouterState.reducer
};

@Injectable()
class GlobalEffects {
  errorHandler$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(GlobalErrorAction),
        map(({ errorMsg, errorObj }) => {
          console.error('[GLOBAL ERROR ACTION HANDLER]', {
            errorObj,
            errorMsg
          });
          this.toast.error(errorMsg);
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly toast: PinnaklUIToastMessage
  ) {}
}

@NgModule({
  imports: [
    EffectsModule.forRoot([GlobalEffects]),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomRouterStateSerializer
    })
  ]
})
export class AppStateModule {}
