import { Component, Inject } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogData, MatPinnaklModalComponent } from '@pinnakl/shared-ui';
import { OtpChannelType, multiFactorTypes } from '@pinnakl/shared/types';
import { VdrUser } from '@pinnakl/vdr/domain';
import { startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'register-new-user-modal',
  templateUrl: 'register-new-user-modal.component.html',
  styleUrls: ['./register-new-user-modal.component.scss']
})
export class RegisterNewUserModalComponent {
  private readonly phoneRegExp = /^\+[0-9]-[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
  form: UntypedFormGroup;
  vdrUser: VdrUser;
  multiFactorTypes = multiFactorTypes;
  isSubmitted = false;

  constructor(
    private dialogRef: MatDialogRef<MatPinnaklModalComponent<RegisterNewUserModalComponent>>,
    @Inject(MAT_DIALOG_DATA) private readonly data: DialogData,
    private fb: FormBuilder
  ) {
    this.vdrUser = this.data.componentData.vdrUser;
    this.form = this.fb.group({
      firstName: [this.vdrUser.firstName, Validators.required],
      lastName: [this.vdrUser.lastName, Validators.required],
      email: [{ value: this.vdrUser.email, disabled: true }, Validators.required],
      password: [''],
      phoneNumber: [this.vdrUser.phoneNumber],
      otpChannel: [multiFactorTypes.email.value, Validators.required]
    });
    this.watchOtpChannel();
  }

  watchOtpChannel() {
    this.form
      .get('otpChannel')
      .valueChanges.pipe(untilDestroyed(this), startWith(this.form.get('otpChannel').value))
      .subscribe(() => this.updatePhoneValidators());
  }

  updatePhoneValidators() {
    const { otpChannel } = this.form.getRawValue();
    const phoneValidators = [Validators.pattern(this.phoneRegExp)];
    if (otpChannel === OtpChannelType.MOBILE) {
      phoneValidators.push(Validators.required);
    }
    const phoneControl = this.form.get('phoneNumber');
    phoneControl.setValidators(phoneValidators);
    phoneControl.updateValueAndValidity();
  }

  register() {
    this.isSubmitted = true;
    if (this.form.invalid) return;
    this.dialogRef.close(this.form.getRawValue());
  }
}
