import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DrawerModule as KendoDrawerModule } from '@progress/kendo-angular-layout';
import { DrawerComponent } from './drawer.component';
import { Document } from './drawer.constants';
import { DrawerControllerService } from './services/drawer.controller.service';

@NgModule({
  declarations: [DrawerComponent],
  exports: [DrawerComponent],
  imports: [CommonModule, MatSidenavModule, KendoDrawerModule],
  providers: [DrawerControllerService, { provide: Document, useValue: document }]
})
export class DrawerModule {}
