import { createAction, props } from '@ngrx/store';
import { SecurityType } from '@pinnakl/securities/domain';

export enum SecurityTypesActions {
  LoadSecurityTypes = '[Security Types] Load Security Types',
  LoadSecurityTypesSuccess = '[Security Types] Load Security Types Success',
  LoadSecurityTypesFailure = '[Security Types] Load Security Types Failure'
}

export const loadSecurityTypes = createAction(SecurityTypesActions.LoadSecurityTypes);
export const loadSecurityTypesSuccess = createAction(
  SecurityTypesActions.LoadSecurityTypesSuccess,
  props<{ securityTypes: SecurityType[] }>()
);
export const loadSecurityTypesFailure = createAction(
  SecurityTypesActions.LoadSecurityTypesFailure,
  props<{ error: any }>()
);
